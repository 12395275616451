import { SuccessResponse } from '../index';
import { PricingStructure } from '../price-list/types';
import { SubCategory, SupplierUser } from '../user/types';

export interface Product {
  id: number;
  name: string;
  gst: boolean;
  archived_at: string | null;
  brand: string | null;
  deleted_at: string | null;
  order_items_count: number;
  picture_url: string | null;
  price_per_unit: string | null;
  shelf_space: string | null;
  sku: string | null;
  slug: string;
  stock_level: StocksEnum;
  unit: string;
  unit_size: string | null;
  product_id: string;
  supplier_id: number;
  location: string | null;
  sub_category: string | null;
  sub_category_id: number | null;
  favorite_products: FavoriteProduct[];
  import_status: string | null;
  supplier: SupplierUser;
  price_structures: PricingStructure[];
  product_price: number;
  supplier_connection_request_status: boolean;
  supplier_company_name: string;
  supplier_company_logo: string;
  barcode: string | null;
  hidden: boolean;
  display_stock: boolean;
  stock_on_hand: number | null;
  stock_available: number | null;
}

export interface ProductListResponse {
  data: {
    products: Product[];
    current_page: number;
    per_page: number;
    previous_page: number | null;
    next_page: number | null;
    total_pages: number;
  };
  products_result: number;
}

export interface FavoriteProduct {
  id: number;
  product_id: number;
  user_id: number;
  favorite_type: 'by_supplier' | 'by_user';
}

export enum StocksEnum {
  LOW = 'low',
  OUT = 'out_of_stock',
  IN = 'in_stock',
}

export interface UpdateProductRequest {
  id: number;
  showSuccessModal?: boolean;
  refetchPriceList?: boolean;
  formData: FormData;
}

export interface UpdateProductRequestJson {
  id: number;
  showSuccessModal?: boolean;
  refetchPriceList?: boolean;
  product: {
    name?: string;
    description?: string;
    product_id?: string;
    gst?: boolean;
    brand?: string;
    picture?: any;
    price_per_unit?: string;
    price_cents?: number;
    shelf_space?: string;
    stock_level?: StocksEnum;
    unit?: string;
    unit_size?: string;
    location?: string;
    sub_category_id?: number;
  };
}

export type SortDir = 'asc' | 'desc';

export interface GetProductRequestParams {
  sort?: ProductsSortBy;
  filter?: ProductFilterValues;
  keyword?: string;
  currentPage?: number;
  orderUserId?: number;
}

export interface GetProductsSuggestionsRequest extends GetProductRequestParams {
  supplierMode?: boolean;
}

export type ProductField = keyof Pick<
  Product,
  | 'name'
  | 'brand'
  | 'price_per_unit'
  | 'shelf_space'
  | 'unit'
  | 'location'
  | 'sub_category'
  | 'product_id'
  | 'stock_level'
  | 'unit_size'
  | 'barcode'
>;

export type ProductsSortBy = [ProductField, SortDir] | ['relevance'];

export enum StockLevelForRequest {
  OUT = '0',
  LOW = '1',
  IN = '2',
}

export interface ProductQueryParams {
  'q[sort_by]'?: string;
  'q[active_product]'?: 'true';
  'q[archived_product]'?: 'true';
  'q[stock]'?: string;
  'q[gst]'?: string;
  'q[min_cost]'?: string;
  'q[max_cost]'?: string;
}

export interface ProductFilterValues {
  stock?: StockLevelForRequest[];
  gst?: string[];
  min_cost?: string;
  max_cost?: string;
  status?: string[];
  supplier_id?: number | null;
  category_id?: number | null;
  sub_category_id?: number | null;
  favourites?: boolean;
  recently_purchased?: boolean;
}

export interface Category {
  id: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  name: string;
}

export interface GetProductByCategoryResponse {
  data: {
    products: Product[];
    suggestions: string[];
    current_page: number;
    next_page: number | null;
  };
  products_result: number;
}

export interface FavouriteResponse extends SuccessResponse {
  product: Product;
}

export interface ExportedProduct {
  'Product Id': string | null;
  'Product Name*': string;
  Brand: string | null;
  'Unit of Measure*': string;
  'Unit size': string | null;
  'Stock level': string | null;
  Location: string | null;
  'Sub category': string | null;
  Shelf: string | null;
  'Your cost': string | null;
  'Tax*': string | null;
  'Picture URL': string | null;
}

export interface ExportedManualSupplierProduct {
  'Product ID': string | null;
  'Product Name*': string;
  Brand: string | null;
  'Unit of Measure*': string;
  'Stock level': string | null;
  'Sub category': string | null;
  Price: string | null;
  'Tax*': string | null;
  'Picture URL': string | null;
}

export interface ExportProductsResponse extends SuccessResponse {
  data: ExportedProduct[];
  headers: { label: string; key: string }[];
}

export interface ExportManualSupplierProductsResponse extends SuccessResponse {
  data: ExportedManualSupplierProduct[];
  headers: { label: string; key: string }[];
}

export interface ProductPreview {
  product_id: string | null;
  name: string;
  brand: string;
  unit: string;
  gst: boolean;
  price_per_unit: number | null;
  sub_category_id: number | null;
  sub_category: string | null;
  unit_size: string | null;
  stock_level: string | null;
  location: string;
  shelf_space: string | null;
  picture: string;
  code?: string;
}

export interface ProductPreviewWithError extends ProductPreview {
  field_errors: { [key: string]: { [key: string]: string } };
}

export interface PreviewProductsResponse extends SuccessResponse {
  added: ProductPreview[];
  archived: ProductPreview[];
  changed: ProductPreview[];
  unchanged: ProductPreview[];
  error_data: ProductPreviewWithError[];
}

export enum ImportProductStatus {
  NOT_IMPORTED_PENDING,
  NOT_IMPORTED,
  IMPORTED_PENDING,
  IMPORTED,
  COMPLETED,
}

export interface SupplierSubCategoryResponse extends SuccessResponse {
  sub_categories: SubCategory[];
}

export interface UpdateProductResponse extends SuccessResponse {
  product: Product;
}

export interface ManualProductPreview {
  brand: string | null;
  code: string | null;
  gst: boolean;
  name: string | null;
  product_id: string | null;
  unit: string | null;
  picture: string | null;
  price_per_unit: number | null;
  sub_category: string | null;
  barcode: string | null;
}

export interface ManualProductPreviewWithError extends ManualProductPreview {
  field_errors: { [key: string]: { [key: string]: string } };
}

export interface PreviewManualProductsResponse extends SuccessResponse {
  added: ManualProductPreview[];
  changed: ManualProductPreview[];
  error_data: ManualProductPreviewWithError[];
}

export interface UpdateManualSupplierProduct {
  id: number;
  name: string;
  product_id?: string;
  brand?: string | null;
  sub_category?: string | null;
  gst?: boolean;
  unit: string;
  picture?: File | null | string;
  product_price?: number;
  barcode?: string | null;
}
