import { useLocation } from 'react-router-dom';

import useInterval from '../../shared/hooks/use-interval';
import useTimeout from '../../shared/hooks/use-timeout';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getImportProductsLoading,
  getProductSortFilters,
  setProductsByCategoryKeyword,
  setProductsKeyword,
  setProductsPage,
  setProductsSortBy,
} from '../../store/products';
import { getIsRestaurant } from '../../store/user';
import { rootApi } from '../index';
import { useGetProductByIdQuery, useGetProductsQuery } from './index';

export const useGetProductListWithParams = () => {
  const { sort, filter, currentPage, keyword } = useAppSelector(getProductSortFilters);

  const { products, totalPages, productsLoading, productsFetching, productsCount } = useGetProductsQuery(
    { sort, filter, currentPage, keyword },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        products: data?.data.products || [],
        totalPages: data?.data.total_pages || 0,
        productsLoading: isLoading,
        productsFetching: isFetching,
        productsCount: data?.products_result || 0,
      }),
    },
  );
  return { products, totalPages, productsLoading, productsFetching, productsCount };
};

export const useSelectProductFromList = (id: number, skip: boolean = false) => {
  const { sort, filter, currentPage, keyword } = useAppSelector(getProductSortFilters);

  const { productFromList } = useGetProductsQuery(
    { sort, filter, currentPage, keyword },
    {
      selectFromResult: ({ data }) => ({
        productFromList: data?.data.products?.find((productItem) => productItem.id === id),
      }),
      skip,
    },
  );

  const { data: productById } = useGetProductByIdQuery(id, {
    skip: !id || !!productFromList,
  });

  return { product: productFromList || productById };
};

export const useSearchByProductName = (name: string, orderUserId?: number, allowEmpty?: boolean) => {
  const { products, productsLoading } = useGetProductsQuery(
    { keyword: name, orderUserId: orderUserId },
    {
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        products: data?.data.products || [],
        productsLoading: isLoading || isFetching,
      }),
      skip: !allowEmpty && !name.trim(),
    },
  );
  return { products, productsLoading };
};

export const useSetProductsDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const { keyword, sort, currentPage } = useAppSelector(getProductSortFilters);
  useTimeout(
    () => {
      dispatch(isRestaurant ? setProductsByCategoryKeyword({ keyword: undefined }) : setProductsKeyword({ keyword: undefined }));
    },
    (pathname === '/dashboard' && isRestaurant) ||
      (pathname === '/categories' && isRestaurant) ||
      pathname.includes('/menu_costing/recipe') ||
      (pathname.includes('/products') && !isRestaurant) ||
      !keyword
      ? null
      : 300000,
  );
  if (!pathname.includes('/products') && !isRestaurant) {
    LocalStorageService.clear('products_scroll');
    sort && dispatch(setProductsSortBy(undefined));
    currentPage !== 1 && dispatch(setProductsPage(1));
  }
  if (pathname !== '/dashboard' && pathname !== '/categories' && isRestaurant) {
    LocalStorageService.clear('last_product_sort');
  }
};

export const useRefetchProducts = () => {
  const importLoading = useAppSelector(getImportProductsLoading);
  const dispatch = useAppDispatch();

  const invalidateProducts = () => {
    dispatch(rootApi.util?.invalidateTags([{ type: 'Product', id: 'LIST' }]));
  };
  useInterval(invalidateProducts, importLoading ? 10000 : null);
};
