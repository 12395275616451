export const GDomains = [
  'demo.theopenpantry.com',
  'app.theopenpantry.com',
  // 'app.kitchenmate.com.au',
  'localhost'
];

export class GoogleIdentityService {
  static checkIfGoogleAvailable() {
    return GDomains.includes(window.location.hostname);
  }
  static init() {
    if (!this.checkIfGoogleAvailable()) {
      return;
    }
    try {
      const sjs = document.getElementsByTagName('script')[0];
      if (document.getElementById('gid-script')) return;
      const js = document.createElement('script') as HTMLScriptElement;
      js.id = 'gid-script';
      js.src = 'https://accounts.google.com/gsi/client';
      js.async = true;
      js.defer = true;
      sjs.parentNode?.insertBefore(js, sjs);
    } catch (e) {
      console.error(e);
    }
  }

  static getIdToken(callback: (id_token: string) => void) {
    this.init();
    if (!this.checkIfGoogleAvailable()) {
      return;
    }
    try {
      window.onGoogleLibraryLoad = () => {
        const googleApi = google as unknown as { accounts: any };
        const handleResponse = (response: any) => {
          response.credential && callback(response.credential);
        };
        googleApi.accounts.id.initialize({
          client_id: process.env.REACT_APP_AUTH0_IDENTIFY_ID as string,
          callback: handleResponse,
        });
        googleApi.accounts.id.renderButton(document.getElementById('googleSignInBtn'), {
          shape: 'circle',
          text: 'continue_with',
          width: 260,
        });
      };
    } catch (e) {
      console.error(e);
    }
  }

  static onLogout() {
    try {
      const googleApi = google as unknown as { accounts: any };
      googleApi.accounts.id.disableAutoSelect();
    } catch (e) {
      console.error(e);
    }
  }
}
