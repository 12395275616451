import React, { useCallback } from 'react';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik } from 'formik';

import { NavbarTitle } from '../../../shared/components/navbar-title';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, getMySubscriptionType, openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';
import { FormikInput } from '../../../shared/components/formik-input';
import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../../api/integrations';
import { ThemedButton } from '../../../shared/components/themed-button';
import { ToastService } from '../../../shared/services/toastService';
import { useMeQuery } from '../../../api/user';
import { FormLabel, Grid } from '@material-ui/core';
import xeroLogo from '../../../assets/images/xero_logo.png';
import opLogo from '../../../assets/images/op_logo.png';
import myobLogo from '../../../assets/images/myob_logo.png';
import line from '../../../assets/images/line.png';
import { useHistory } from 'react-router';
import { UpgradeSubscriptionTooltip } from '../../../shared/components/upgrade-subscription-tooltip';
import { SubscriptionPlanEnum } from '../../../api/user/types';

export const Integrations: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const { data: user } = useMeQuery();
  const title = 'Integrations';

  const { data } = useGetSettingsQuery();
  const [updateSettings, { isLoading: loading }] = useUpdateSettingsMutation();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmit = ({order_webhook}:{order_webhook: string}) => {
    updateSettings({ webhook: { url: order_webhook } })
      .unwrap()
      .then(() => {
        ToastService.success('The settings have been updated successfully');
      })
      .catch((error) => {
        console.error('Update failed:', error);
      });
  };

  const onContactUsClick = useCallback(() => {
    push('/account/help/contact');
  }, []);

  const onXeroClick = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_API_URL}/xero/connect?user_id=${user?.id}`;
  }, []);

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} />}
        bottomComponent={isMobile ? <NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            <div className={classes.title}>{title}</div>
            <Grid container spacing={3} className={classes.services}>
              <Grid item xs className={classes.serviceItem}>
                <div className={classes.logo}><img src={opLogo} /></div>
                <p className={classes.logoText}>Manage your products and prices via API. Receive orders via webhook</p>
                <img src={line} />
                <ThemedButton isSmall={true} onClick={onContactUsClick} className={classes.logoBtn} title='Contact Us' width={222} />
              </Grid>
              <Grid item xs className={classes.serviceItem}>
                <div className={classes.logo}><img src={xeroLogo} /></div>
                <p className={classes.logoText}>Synchronise your invoices with Xero</p>
                <img src={line} />
                {
                  subscriptionType === SubscriptionPlanEnum.FREE
                    ? <UpgradeSubscriptionTooltip
                        text='Upgrade your subscription plan to unlock ‘Xero’ options' placement={'bottom-end'}>
                        <ThemedButton
                          isSmall={true} className={classes.logoBtn} title='Connect' width={222}
                          disabled={true}
                        />
                      </UpgradeSubscriptionTooltip>
                    : <ThemedButton
                        isSmall={true} width={222}
                        className={clsx(classes.logoBtn, user?.has_xero_connection && classes.connected)}
                        title={user?.has_xero_connection ? 'Connected' : 'Connect'}
                        onClick={onXeroClick}
                      />
                }
              </Grid>
              <Grid item xs className={classes.serviceItem}>
                <div className={classes.logo}><img src={myobLogo} /></div>
                <p className={classes.logoText}>Synchronise your invoices MYOB</p>
                <img src={line} />
                <UpgradeSubscriptionTooltip text='Upgrade your subscription plan to unlock ‘MYOB’ options' placement={'bottom-end'}>
                  <ThemedButton
                    isSmall={true} className={classes.logoBtn} title='Connect' width={222}
                    disabled={true}
                  />
                </UpgradeSubscriptionTooltip>
              </Grid>
            </Grid>
            <div className={classes.contactUs}>
              <p>Don’t see your system?</p>
              <p>Get in contact and we can see how we can connect with you</p>
              <ThemedButton isSmall={true} onClick={onContactUsClick} className={classes.logoBtn} title='Contact Us' width={222} />
            </div>
            <div style={{display: 'none'}}>
              <Formik
                sty
                initialValues={{
                  api_key: data?.api_key.key || '',
                  order_webhook: data?.webhook.url || '',
                  user_id: user?.id || '',
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ submitForm }) => (
                <Form className={classes.formBox}>
                  <div className={classes.fieldWrap}>
                    <Field name='user_id'>
                      {(fieldProps: FieldProps) => (
                        <FormLabel className={classes.userId}>User ID: {fieldProps.field.value}</FormLabel>
                      )}
                    </Field>
                  </div>
                  <div className={classes.fieldWrap}>
                    <Field name='api_key'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput {...fieldProps} label='API Key' placeholder='API Key' disabled={true} />
                      )}
                    </Field>
                  </div>
                  <div className={classes.fieldWrap}>
                    <Field name='order_webhook'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput {...fieldProps} label='Order Webhook' placeholder='ex: https://localhost/order' disabled={false} />
                      )}
                    </Field>
                  </div>
                  <div className={classes.btnBox}>
                    <ThemedButton disabled={loading} onClick={submitForm} title='Update' width={200} />
                  </div>
                </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};