import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { format, isBefore, isToday } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import { RangeOptions } from '../../shared/components/range-dropdown';
import { calculateProfileProgress } from '../../shared/helpers/calculate-profile-progress';
import { getCategoriesFromIds } from '../../shared/helpers/getCategoryId';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCustomersPerformanceSortFilters } from '../../store/customers-performance';
import { getDashboardMode, getSalesRange, getSelectedDate, setDashboardMode } from '../../store/dashboard';
import { DashboardMode } from '../../store/dashboard/dashboard.reducer';
import { setMenuCostingVideoModalOpened, setRpsVideoModalOpened } from '../../store/menu/menu.actions';
import { getProductsPurchasedSortFilters, resetProductsPurchasedParams } from '../../store/products-purchased';
import { getMySubscriptionType } from '../../store/user';
import { useGetMyCompanyQuery } from '../company';
import { ContactType, DeliveryDay, SupplierProfile } from '../company/types';
import { useGetCustomerListQuery } from '../customer';
import { useGetAllInventories } from '../inventory/hooks';
import { useGetActiveRecipes } from '../menu/hooks';
import { useGetOrdersQuery } from '../order';
import { useGetPantryListsQuery } from '../pantry-list';
import { useGetPriceListsQuery } from '../price-list';
import { useGetProductsQuery } from '../product';
import { useGetAllSalesQuery } from '../sales';
import { useGetMySuppliers } from '../supplier/hooks';
import { useMeQuery } from '../user';
import { RPSOnboardingSteps, SubscriptionPlanEnum } from '../user/types';
import { useGetAllWastagesQuery } from '../wastages';
import {
  useGetCustomersPerformanceQuery,
  useGetOrdersDashboardQuery,
  useGetProductsPurchasedDeliveryQuery,
  useGetProductsPurchasedQuery,
  useGetPurchasesGraphQuery,
  useGetSalesGraphQuery,
  useGetSupplierDashboardQuery,
} from './index';

export const useGetCustomersPerformance = () => {
  const { currentPage, keyword, sort, filter } = useAppSelector(getCustomersPerformanceSortFilters);
  const { customers, loading, totalPages, loaded } = useGetCustomersPerformanceQuery(
    { currentPage, keyword, sort, filter },
    {
      selectFromResult: ({ data, isLoading, isFetching, isSuccess }) => ({
        customers: data?.data || [],
        loading: isLoading || isFetching,
        totalPages: data?.total_pages || 0,
        loaded: isSuccess,
      }),
    },
  );
  return { customers, loading, totalPages, loaded };
};

export const useGetRestaurantMenuCostingOnBoardingState = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useMeQuery();
  const { layoutCompleted } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company.id,
    selectFromResult: ({ data, isFetching }) => ({
      layoutCompleted: !!data?.menus?.length,
      companyLoading: isFetching,
    }),
  });
  const menuOnboarding = user && 'menu_costing_onboarding' in user ? user.menu_costing_onboarding : null;

  const isSuppliersStepCompleted = Array.isArray(menuOnboarding) && !menuOnboarding.includes('Invite 2+ suppliers');
  const isMenuAdded = Array.isArray(menuOnboarding) && !menuOnboarding.includes('Create one recipe');
  const isOrdersPlaced = Array.isArray(menuOnboarding) && !menuOnboarding.includes('Place one order');

  const menuOnboardingFinished = layoutCompleted && isSuppliersStepCompleted && isOrdersPlaced && isMenuAdded;

  const debouncedOpenVideoModal = useDebouncedCallback(() => {
    dispatch(setMenuCostingVideoModalOpened(!menuOnboardingFinished));
  }, 1000);

  useEffect(debouncedOpenVideoModal, [menuOnboardingFinished]);

  return {
    isSuppliersStepCompleted,
    layoutCompleted,
    isOrdersPlaced,
    isMenuAdded,
    menuOnboarding,
  };
};

export const useGetRestaurantRpsOnBoardingState = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useMeQuery();
  const rpsOnboarding = user && 'pro_suite_onboarding' in user ? user.pro_suite_onboarding : null;

  const isSuppliersStepCompleted = Array.isArray(rpsOnboarding) && !rpsOnboarding.includes(RPSOnboardingSteps.INVITE);
  const isMenuAdded = Array.isArray(rpsOnboarding) && !rpsOnboarding.includes(RPSOnboardingSteps.MC);
  const isOrdersPlaced = Array.isArray(rpsOnboarding) && !rpsOnboarding.includes(RPSOnboardingSteps.ORDER);
  const isInventoryDone = Array.isArray(rpsOnboarding) && !rpsOnboarding.includes(RPSOnboardingSteps.INVENTORY);
  const isAutomatedDone = Array.isArray(rpsOnboarding) && !rpsOnboarding.includes(RPSOnboardingSteps.AUTOMATED);
  const nextStepIdx = Array.isArray(rpsOnboarding) && Object.values(RPSOnboardingSteps).indexOf(rpsOnboarding[0]);

  const rpsOnboardingFinished = isSuppliersStepCompleted && isMenuAdded && isOrdersPlaced && isInventoryDone && isAutomatedDone;

  const debouncedOpenVideoModal = useDebouncedCallback(() => {
    dispatch(setRpsVideoModalOpened(!rpsOnboardingFinished));
  }, 1000);

  useEffect(debouncedOpenVideoModal, [rpsOnboardingFinished]);

  return {
    isSuppliersStepCompleted,
    isInventoryDone,
    isAutomatedDone,
    isOrdersPlaced,
    isMenuAdded,
    rpsOnboardingFinished,
    nextStepIdx,
  };
};

export const useGetRestaurantDashboardOnBoardingState = () => {
  const plan = useAppSelector(getMySubscriptionType);
  const { data: user } = useMeQuery();
  const { suppliers, suppliersLoading } = useGetMySuppliers(true, true);
  const { list, loading: recipeLoading } = useGetActiveRecipes();
  const { data: salesRes, isFetching: salesLoading } = useGetAllSalesQuery(undefined, {
    skip: plan !== SubscriptionPlanEnum.HOSPO,
  });
  const { data: wastageRes, isFetching: wastageLoading } = useGetAllWastagesQuery(undefined, {
    skip: plan !== SubscriptionPlanEnum.HOSPO,
  });
  const inventories = useGetAllInventories();
  const { orders, ordersLoading } = useGetOrdersQuery(
    {},
    {
      selectFromResult: ({ data, isFetching }) => ({
        orders: data?.orders || [],
        ordersLoading: isFetching,
      }),
    },
  );

  const { pantryListsExist, pantryListLoading } = useGetPantryListsQuery(
    { currentPage: 1 },
    {
      selectFromResult: ({ data, isFetching }) => ({
        pantryListsExist: !!data?.pantry_lists?.length,
        pantryListLoading: isFetching,
      }),
    },
  );

  const isSuppliersStepCompleted = !!suppliers.length;
  const isOrdersPlaced = !!orders.length;
  const isMenuAdded = !!list?.length;
  const isInventoryAdded = !!inventories?.length;
  const isSalesWastageAdded = !!salesRes?.sales.length || !!wastageRes?.wastages.length;
  const isAutoOrderingDone = !!user?.autofill_once;
  const loading = suppliersLoading || recipeLoading || ordersLoading || pantryListLoading || salesLoading || wastageLoading;
  return {
    isSuppliersStepCompleted,
    isOrdersPlaced,
    isOnBoardingStepsCompleted:
      (isSuppliersStepCompleted && isOrdersPlaced && plan === SubscriptionPlanEnum.NORMAL) ||
      (isSuppliersStepCompleted && isOrdersPlaced && isMenuAdded && plan === SubscriptionPlanEnum.MENU) ||
      (isSuppliersStepCompleted &&
        isOrdersPlaced &&
        isMenuAdded &&
        isInventoryAdded &&
        isSalesWastageAdded &&
        isAutoOrderingDone &&
        plan === SubscriptionPlanEnum.HOSPO),
    pantryListsExist,
    isMenuAdded,
    isInventoryAdded,
    isSalesWastageAdded,
    isAutoOrderingDone,
    loading,
  };
};

export const useGetDashboardOnBoardingState = () => {
  const {
    isPriceStepCompleted, priceListsLoading,
    isProductStepCompleted, productsLoading,
    isCustomerStepCompleted, customerLoading,
    isOrdersPlaced, ordersLoading, ordersSuccess,
  } = useGetSupplierDashboardQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching, isSuccess }) => ({
      isPriceStepCompleted: data?.dashboard.is_price_step_completed,
      priceListsLoading: isLoading || isFetching,
      isProductStepCompleted: data?.dashboard.is_product_step_completed,
      productsLoading: isLoading || isFetching,
      isCustomerStepCompleted: data?.dashboard.is_customer_step_completed,
      customerLoading: isLoading || isFetching,
      isOrdersPlaced: data?.dashboard.is_orders_placed,
      ordersLoading: isLoading || isFetching,
      ordersSuccess: isSuccess,
    }),
  });

  // const { products, productsLoading } = useGetProductsQuery(
  //   { currentPage: 1 },
  //   {
  //     selectFromResult: ({ data, isLoading, isFetching }) => ({
  //       products: data?.data.products || [],
  //       productsLoading: isLoading || isFetching,
  //     }),
  //   },
  // );
  // const { customers, customerLoading } = useGetCustomerListQuery(
  //   { currentPage: 1 },
  //   {
  //     selectFromResult: ({ data, isLoading, isFetching }) => ({
  //       customers: data?.customers || [],
  //       customerLoading: isLoading || isFetching,
  //     }),
  //   },
  // );
  // const { isPriceStepCompleted, priceListsLoading } = useGetPriceListsQuery(undefined, {
  //   selectFromResult: ({ data = [], isLoading }) => ({
  //     isPriceStepCompleted: !!data.find((pList) => pList.price_structures.some((el) => !!el.margin || el.price_cents !== 0)),
  //     priceListsLoading: isLoading,
  //   }),
  // });

  // const { orders, ordersLoading, ordersSuccess } = useGetOrdersQuery(
  //   { currentPage: 1 },
  //   {
  //     selectFromResult: ({ data, isLoading, isFetching, isSuccess }) => ({
  //       orders: data?.orders || [],
  //       ordersLoading: isLoading || isFetching,
  //       ordersSuccess: isSuccess,
  //     }),
  //   },
  // );

  let profileStepCompleted = false;
  let profileLoading = true;
  const { data: user, isLoading: userLoading } = useMeQuery();
  const { data: company, isLoading: companyLoading } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company.id,
  });
  if (user && company) {
    const deliveryRadiusData = 'delivery_radiuses' in user ? user?.delivery_radiuses.find((el) => el.supplier_id === user.id) : undefined;
    const deliveryDetail = company.delivery_details[0];
    const profileData: Partial<SupplierProfile> = {
      name: company.name,
      bio: company.bio || '',
      website: company.website || '',
      picture_url: company.picture_url || '',
      min_order_value: (deliveryDetail?.minimum_order_value_cents || 0) / 100,
      cutoff: deliveryDetail?.cutoff,
      delivery_detail_id: deliveryDetail?.id,
      delivery_days: Object.values(DeliveryDay).filter((day) => deliveryDetail && deliveryDetail[day]),
      categories: getCategoriesFromIds(company.categories),
      delivery_radius: deliveryRadiusData?.delivery_radius,
      delivery_radius_id: deliveryRadiusData?.id,
      delivery_fee: (deliveryDetail?.delivery_fee?.cents || 0) / 100,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      contacts: company.contacts || [],
      office_email: company.contacts?.find((el) => el.contact_type === ContactType.OFFICE)?.email || '',
      office_phone: company.contacts?.find((el) => el.contact_type === ContactType.OFFICE)?.phone_number || '',
      address: company.addresses[0],
    };
    profileStepCompleted = calculateProfileProgress(profileData) >= 70;
    profileLoading = userLoading || companyLoading;
  }

  // const isProductStepCompleted = !!products.length;
  // const isCustomerStepCompleted = !!customers.length;
  // const isOrdersPlaced = !!orders.length;
  return {
    isCustomerStepCompleted,
    isProductStepCompleted,
    isPriceStepCompleted,
    isOrdersPlaced,
    productsLoading,
    customerLoading,
    priceListsLoading,
    ordersLoading,
    ordersSuccess,
    profileStepCompleted,
    profileLoading,
    isOnBoardingStepsCompleted: isProductStepCompleted && isPriceStepCompleted && isCustomerStepCompleted && profileStepCompleted,
    completedStepsCount: [isProductStepCompleted, isPriceStepCompleted, isCustomerStepCompleted, profileStepCompleted].filter((st) => !!st)
      .length,
  };
};

export const useSetDashboardDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { filter } = useAppSelector(getProductsPurchasedSortFilters);
  const { isOrdersPlaced } = useGetDashboardOnBoardingState();
  const dashboardMode = useAppSelector(getDashboardMode);
  const { todayOrdersExist } = useGetOrdersDashboardQuery(undefined, {
    selectFromResult: ({ data }) => ({
      todayOrdersExist: !!data?.orders,
    }),
  });
  (todayOrdersExist || isOrdersPlaced) && dashboardMode !== DashboardMode.FILLED && dispatch(setDashboardMode(DashboardMode.FILLED));

  useEffect(() => {
    if (!filter) {
      return;
    }
    if (filter && (pathname.includes('/tools') || pathname.includes('/dashboard'))) {
      return;
    }
    dispatch(resetProductsPurchasedParams());
  }, [pathname, !!filter]);
};

export const useGetDashboardOrdersStats = () => {
  const date = useAppSelector(getSelectedDate);
  const { sales, orders } = useGetOrdersDashboardQuery(
    isToday(new Date(date)) ? undefined : { date: format(new Date(date), 'yyyy-MM-dd') },
    {
      selectFromResult: ({ data }) => ({
        new_orders: data?.new_orders || 0,
        sales: data?.sales || 0,
        orders: data?.orders || 0,
      }),
      refetchOnMountOrArgChange: true,
    },
  );
  return { sales, orders };
};

export const useGetProductsPurchased = () => {
  const { sort, filter, currentPage, keyword } = useAppSelector(getProductsPurchasedSortFilters);
  const { data: totalValues } = useGetProductsPurchasedDeliveryQuery({ sort, filter, currentPage, keyword });
  const { list, totalPages, loading, purchasedExists } = useGetProductsPurchasedQuery(
    { sort, filter, currentPage, keyword },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        list: data?.data || [],
        loading: isLoading || isFetching,
        totalPages: data?.total_pages || 0,
        purchasedExists: !!data?.total_products_ordered,
      }),
    },
  );
  return { list, totalPages, loading, purchasedExists, totalValues };
};

export const useGetSalesData = () => {
  const { days, range, startDate, endDate } = useAppSelector(getSalesRange);

  const { chartData, loading, fetching } = useGetSalesGraphQuery(
    {
      days: range === RangeOptions.CUSTOM && startDate && endDate ? undefined : days,
      '[date_range][from_date]': startDate,
      '[date_range][to_date]': endDate,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        loading: isLoading,
        fetching: isFetching,
        chartData: data?.data
          ? Object.entries(data.data)
              .map(([key, value]: [string, number]) => ({
                date: new Date(key),
                value,
              }))
              .sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1))
          : [],
      }),
    },
  );
  return {
    chartData,
    range: !loading && !fetching ? range : null,
    loading,
    fetching,
  };
};

export const useGetPurchasesData = () => {
  const { days, range, startDate, endDate } = useAppSelector(getSalesRange);

  const { chartData, previousChartData, loading, fetching } = useGetPurchasesGraphQuery(
    {
      days: range === RangeOptions.CUSTOM && startDate && endDate ? undefined : days,
      '[date_range][from_date]': startDate,
      '[date_range][to_date]': endDate,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        loading: isLoading,
        fetching: isFetching,
        chartData: data?.data
          ? Object.entries(data.data)
              .map(([key, value]: [string, number]) => ({
                date: new Date(key),
                value,
              }))
              .sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1))
          : [],

        previousChartData: data?.previous_data
          ? Object.entries(data.previous_data)
              .map(([key, value]: [string, number]) => ({
                date: new Date(key),
                value,
              }))
              .sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1))
          : [],
      }),
    },
  );
  return {
    chartData,
    previousChartData: chartData.map((el, idx) => ({
      date: el.date,
      value: previousChartData[idx]?.value || 0,
    })),
    range: !loading && !fetching ? range : null,
    loading,
    fetching,
  };
};
