import React, { useEffect, useState } from 'react';

import { Collapse } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { OrderSortBy } from '../../../../../../api/order/types';
import { StockLevelForRequest } from '../../../../../../api/product/types';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../../shared/components/common-checkbox';
import { DateRangeComponent } from '../../../../../../shared/components/date-range';
import { FormikCheckbox } from '../../../../../../shared/components/formik-checkbox';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { FormikInput } from '../../../../../../shared/components/formik-input';
import { getCurrency } from '../../../../../../shared/helpers/getCurrency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getOrdersSortFilters, setOrdersSortFilter } from '../../../../../../store/orders';

import { useStyles } from './style';

export const orderSortParams = [
  { label: 'Order number (A - Z)', value: 'number asc' },
  { label: 'Order number (Z - A)', value: 'number desc' },
  { label: 'Customer name (A - Z)', value: 'user_company_name asc' },
  { label: 'Customer name (Z - A)', value: 'user_company_name desc' },
  { label: 'Order status (A - Z)', value: 'export_status asc' },
  { label: 'Order status (Z - A)', value: 'export_status desc' },
  { label: 'Order Value (Low to High)', value: 'total asc' },
  { label: 'Order Value (High to Low)', value: 'total desc' },
  { label: 'Order date (Most recent to Old)', value: 'created_at desc' },
  { label: 'Order date (Old to Most recent)', value: 'created_at asc' },
  { label: 'Delivery date (Most recent to Old)', value: 'est_delivery_date desc' },
  { label: 'Delivery date (Old to Most recent)', value: 'est_delivery_date asc' },
  { label: 'Order notes (A - Z)', value: 'notes asc' },
  { label: 'Order notes (Z - A)', value: 'notes desc' },
  { label: 'Deliver to (Low to High)', value: 'address_postcode asc' },
  { label: 'Deliver to (High to Low)', value: 'address_postcode desc' },
  { label: 'Delivery number (Low to High)', value: 'delivery_number asc' },
  { label: 'Delivery number (High to Low)', value: 'delivery_number desc' },
];

interface Values {
  sortBy: string;
  stock?: StockLevelForRequest[];
  statusOption?: string[];
  gstOption?: string[];
  notesOption?: boolean;
  minCost?: number;
  maxCost?: number;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
  hideDeliveryDate?: boolean;
}

export const OrdersFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen, hideDeliveryDate }) => {
  const classes = useStyles();
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();
  const [deliveryToday, setDeliveryToday] = useState(false);

  const [expandedFields, setExpandedFields] = useState<{ stock: boolean; status: boolean; notes: boolean }>({
    stock: false,
    status: false,
    notes: false,
  });
  const [deliveryDate, setDeliveryDate] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: undefined,
    endDate: undefined,
  });
  const [orderDate, setOrderDate] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: undefined,
    endDate: undefined,
  });

  const { sort, filter } = useAppSelector(getOrdersSortFilters);

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<any>) => {
    dispatch(
      setOrdersSortFilter({
        sort: values.sortBy.split(' ') as OrderSortBy,
        filter: {
          notes: values.notesOption,
          export_status: values.statusOption,
          stock: values.stock,
          gst: values.gstOption,
          min_total_price: values.minCost,
          max_total_price: values.maxCost,
          order_from_date: orderDate.startDate ? format(orderDate.startDate, 'yyyy-MM-dd') : undefined,
          order_to_date: orderDate.endDate ? format(orderDate.endDate, 'yyyy-MM-dd') : undefined,
          delivery_from_date: deliveryDate.startDate ? format(deliveryDate.startDate, 'yyyy-MM-dd') : undefined,
          delivery_to_date: deliveryDate.endDate ? format(deliveryDate.endDate, 'yyyy-MM-dd') : undefined,
          orders_for_delivery_today: deliveryToday,
        },
      }),
    );
    handleCloseModal();
    formikHelpers.resetForm();
  };

  const validateForm = (values: Values) => {
    const errors: any = {};
    if (values.minCost && values.maxCost && +values.minCost > +values.maxCost) {
      errors.maxCost = 'Max value is too low';
    }
    return errors;
  };

  const expandHandler = (field: 'stock' | 'status' | 'notes') => {
    setExpandedFields((state) => ({ ...state, [field]: !state[field] }));
  };

  const handleDeliveryDateFields = (data: { startDate?: Date; endDate?: Date }) => {
    setDeliveryDate(data);
  };

  const handleOrderDateFields = (data: { startDate?: Date; endDate?: Date }) => {
    setOrderDate(data);
  };

  const resetFields = () => {
    dispatch(setOrdersSortFilter({ sort: undefined, filter: undefined }));
    if (!filter) {
      setDeliveryToday(false);
      setDeliveryDate({
        startDate: undefined,
        endDate: undefined,
      });
      setOrderDate({
        startDate: undefined,
        endDate: undefined,
      });
    }
  };

  const toggleDeliveryToday = () => {
    setDeliveryDate({
      startDate: undefined,
      endDate: undefined,
    });
    setDeliveryToday((prev) => !prev);
  };

  useEffect(() => {
    setOrderDate({
      startDate: filter?.order_from_date ? new Date(filter.order_from_date) : undefined,
      endDate: filter?.order_to_date ? new Date(filter.order_to_date) : undefined,
    });
  }, [filter?.order_to_date, filter?.order_from_date]);

  useEffect(() => {
    setDeliveryDate({
      startDate: filter?.delivery_from_date ? new Date(filter.delivery_from_date) : undefined,
      endDate: filter?.delivery_to_date ? new Date(filter.delivery_to_date) : undefined,
    });
  }, [filter?.delivery_from_date, filter?.delivery_to_date]);

  useEffect(() => {
    setDeliveryToday(!!filter?.orders_for_delivery_today);
  }, [filter?.orders_for_delivery_today]);

  useEffect(() => {
    deliveryDate.startDate && setDeliveryToday(false);
  }, [deliveryDate]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort?.join(' ') || orderSortParams[8].value,
        notesOption: !!filter?.notes,
        stock: filter?.stock as StockLevelForRequest[] | undefined,
        statusOption: filter?.export_status,
        gstOption: filter?.gst,
        minCost: filter?.min_total_price,
        maxCost: filter?.max_total_price,
      }}
      validate={validateForm}
      enableReinitialize={true}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={orderSortParams[8].value}
                    placeholder='Select sort option'
                    options={orderSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.blockTitle}>FILTER BY</div>
              {!hideDeliveryDate && <div>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>DELIVERY DATE</div>
                <DateRangeComponent
                  startDate={deliveryDate.startDate}
                  endDate={deliveryDate.endDate}
                  onDateChange={handleDeliveryDateFields}
                  trigger={
                    <div className={classes.datesBox}>
                      <div className={classes.datesItem}>
                        {deliveryDate.startDate ? (
                          format(deliveryDate.startDate, 'dd/MM/yy')
                        ) : (
                          <span className={classes.placeholder}>From date</span>
                        )}
                      </div>
                      <div className={classes.divider}>&#8211;</div>
                      <div className={classes.datesItem}>
                        {deliveryDate.endDate ? (
                          format(deliveryDate.endDate, 'dd/MM/yy')
                        ) : (
                          <span className={classes.placeholder}>To date</span>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>}
              {!hideDeliveryDate && <div className={classes.deliveryToday}>
                <CommonCheckbox labelPlace='end' checked={deliveryToday} name='Orders for delivery today' onChange={toggleDeliveryToday} />
              </div>}
              <div className={classes.datesWrap}>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>ORDER DATE</div>
                <DateRangeComponent
                  startDate={orderDate.startDate}
                  endDate={orderDate.endDate}
                  onDateChange={handleOrderDateFields}
                  trigger={
                    <div className={classes.datesBox}>
                      <div className={classes.datesItem}>
                        {orderDate.startDate ? (
                          format(orderDate.startDate, 'dd/MM/yy')
                        ) : (
                          <span className={classes.placeholder}>From date</span>
                        )}
                      </div>
                      <div className={classes.divider}>&#8211;</div>
                      <div className={classes.datesItem}>
                        {orderDate.endDate ? format(orderDate.endDate, 'dd/MM/yy') : <span className={classes.placeholder}>To date</span>}
                      </div>
                    </div>
                  }
                />
              </div>
              <div className={classes.costBoxWrap}>
                <div className={classes.costBlock}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle, classes.costTitle])}>Sales value</div>
                  <div className={classes.costFields}>
                    <div className={classes.costFieldWrap}>
                      <FastField name='minCost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} min`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                    <div className={classes.divider}>&#8211;</div>
                    <div className={classes.costFieldWrap}>
                      <FastField name='maxCost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} max`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                  </div>
                </div>
                <div className={classes.gstBox}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle, classes.gstTitle])}>Tax</div>
                  <div className={classes.gstFields}>
                    <FastField type='checkbox' name='gstOption' value='1'>
                      {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Yes' />}
                    </FastField>
                    <FastField type='checkbox' name='gstOption' value='0'>
                      {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='No' />}
                    </FastField>
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.triggerBox}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>STOCK</div>
                  {expandedFields.stock ? (
                    <div className={classes.actions} onClick={expandHandler.bind(null, 'stock')}>
                      <span className={classes.actionsText}>Hide</span>
                      <KeyboardArrowDownIcon className={classes.actionsIcon} />
                    </div>
                  ) : (
                    <div className={classes.actions} onClick={expandHandler.bind(null, 'stock')}>
                      <span className={classes.actionsText}>Show</span>
                      <ChevronRightIcon className={classes.actionsIcon} />
                    </div>
                  )}
                </div>
              </div>
              <Collapse in={expandedFields.stock} timeout={500}>
                <div className={classes.stockBlock}>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.LOW}>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Low' />}
                  </FastField>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.OUT}>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Out of Stock' />}
                  </FastField>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.IN}>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='In Stock' />}
                  </FastField>
                </div>
              </Collapse>
              <div className={classes.triggerBox}>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>STATUS</div>
                {expandedFields.status ? (
                  <div className={classes.actions} onClick={expandHandler.bind(null, 'status')}>
                    <span className={classes.actionsText}>Hide</span>
                    <KeyboardArrowDownIcon className={classes.actionsIcon} />
                  </div>
                ) : (
                  <div className={classes.actions} onClick={expandHandler.bind(null, 'status')}>
                    <span className={classes.actionsText}>Show</span>
                    <ChevronRightIcon className={classes.actionsIcon} />
                  </div>
                )}
              </div>
              <Collapse in={expandedFields.status} timeout={500}>
                <div className={classes.statusBlock}>
                  <FastField type='checkbox' name='statusOption' value='0'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='New order' />}
                  </FastField>
                  <FastField type='checkbox' name='statusOption' value='1'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Exported' />}
                  </FastField>
                  <FastField type='checkbox' name='statusOption' value='2'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Deleted' />}
                  </FastField>
                </div>
              </Collapse>
              <div className={classes.triggerBox}>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>NOTES</div>
                {expandedFields.notes ? (
                  <div className={classes.actions} onClick={expandHandler.bind(null, 'notes')}>
                    <span className={classes.actionsText}>Hide</span>
                    <KeyboardArrowDownIcon className={classes.actionsIcon} />
                  </div>
                ) : (
                  <div className={classes.actions} onClick={expandHandler.bind(null, 'notes')}>
                    <span className={classes.actionsText}>Show</span>
                    <ChevronRightIcon className={classes.actionsIcon} />
                  </div>
                )}
              </div>
              <Collapse in={expandedFields.notes} timeout={500}>
                <div className={classes.notesBox}>
                  <div className={classes.notesItemWrap}>
                    <FastField type='checkbox' name='notesOption'>
                      {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Written note' />}
                    </FastField>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetFields}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
