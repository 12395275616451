import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { useGetCustomersWithoutPriceListsQuery, useGetOrdersDashboardQuery } from '../../../../api/dashboard';
import { useGetRestaurantRpsOnBoardingState } from '../../../../api/dashboard/hooks';
import { useMeQuery, usePrefetch as usePrefetchNotificationSetting } from '../../../../api/user';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import SvgCalculateIcon from '../../../../assets/icons/CalculateIcon';
import SvgCategoryIcon from '../../../../assets/icons/CategoryIcon';
import SvgCustomerIcon from '../../../../assets/icons/CustomerIcon';
import SvgDashboardIcon from '../../../../assets/icons/DashboardIcon';
import SvgHelpIcon from '../../../../assets/icons/HelpIcon';
import SvgHistoryIcon from '../../../../assets/icons/HistoryIcon';
import SvgListIcon from '../../../../assets/icons/ListIcon';
import SvgOrderIcon from '../../../../assets/icons/OrderIcon';
import SvgProductIcon from '../../../../assets/icons/ProductIcon';
import SvgProfileIcon from '../../../../assets/icons/ProfileIcon';
import SvgReportIcon from '../../../../assets/icons/ReportIcon';
import OPLogo from '../../../../assets/images/logo/Logo.svg';
import LogoSmall from '../../../../assets/images/logo/Logo_Small.svg';
import LogoTablet from '../../../../assets/images/logo/Logo_Tablet.svg';
import { CustomMenuButton } from '../../../../shared/components/menu-button';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  closeAccountMenu,
  closeMainMenu,
  getIsAccountMenuOpened,
  getIsMainMenuOpened,
  getIsRestaurant,
  getMySubscriptionType,
  openAccountMenu,
  openMainMenu,
  openUseDesktopForTools,
} from '../../../../store/user';
import { colorVariables } from '../../../../styles/colorVariables';
import { NestedAccountMenu } from '../nested-account-menu';
import { NavLinkItem } from './navLinkItem';
import { useCurrentTheme } from '../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../api/admin-themes/types';

import { useStyles } from './style';

export enum SelectedMenuDropDown {
  customers,
  products,
  orders,
  list,
  suppliers,
  tools,
  restaurants,
  users,
  connections,
  content,
  activities,
  controls,
}

export interface LinkItem {
  title: string;
  link: string;
  key: SelectedMenuDropDown | null;
  exact?: boolean;
  icon?: React.ReactNode;
  nestedLinks?: LinkItem[];
  showCounter?: boolean;
  disabled?: boolean;
  permissionKey?: string;
  requireToUpgrade?: boolean;
  hide?: boolean;
}

const supplierMenuConfig: LinkItem[] = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    exact: true,
    icon: <SvgDashboardIcon />,
    key: null,
    nestedLinks: [],
  },
  {
    title: 'Customers',
    link: '/customers',
    key: SelectedMenuDropDown.customers,
    icon: <SvgCustomerIcon />,
    showCounter: true,
    nestedLinks: [
      {
        title: 'Customer List',
        link: '/customers',
        showCounter: true,
        exact: true,
        key: null,
      },
      {
        title: 'Add New Customer',
        link: '/customers/add',
        key: null,
      },
    ],
  },
  {
    title: 'Products & Pricing',
    link: '/products',
    key: SelectedMenuDropDown.products,
    icon: <SvgProductIcon />,
    nestedLinks: [
      {
        title: 'Product List',
        link: '/products',
        exact: true,
        key: null,
      },
      {
        title: 'Inventory',
        link: '/products/inventory',
        exact: true,
        key: null,
        requireToUpgrade: true,
      },
      {
        title: 'Add/edit product list (XLSX)',
        link: '/products/import',
        key: null,
      },
      {
        title: 'Price Exceptions',
        link: '/products/price-exceptions',
        key: null,
      },
    ],
  },
  {
    title: 'Orders',
    link: '/orders/last',
    key: SelectedMenuDropDown.orders,
    icon: <SvgOrderIcon />,
    showCounter: true,
    nestedLinks: [
      {
        title: 'Next Delivery',
        link: '/orders/next-delivery',
        exact: true,
        showCounter: false,
        key: null,
      },
      {
        title: 'Picking',
        link: '/orders/picking',
        exact: true,
        showCounter: false,
        key: null,
      },
      {
        title: 'Invoiced',
        link: '/orders/invoiced',
        exact: true,
        showCounter: false,
        key: null,
        requireToUpgrade: true,
      },
      {
        title: 'Paid',
        link: '/orders/paid',
        exact: true,
        showCounter: false,
        key: null,
        requireToUpgrade: true,
      },
      {
        title: 'All Orders',
        link: '/orders',
        exact: true,
        showCounter: true,
        key: null,
      },
      {
        title: 'Delivery',
        link: '/orders/delivery',
        exact: true,
        key: null,
        requireToUpgrade: true,
      },
    ],
  },
  {
    title: 'Reporting',
    link: '/tools/reporting',
    key: SelectedMenuDropDown.tools,
    icon: <SvgReportIcon />,
    nestedLinks: [
      {
        title: 'General',
        link: '/tools/reporting',
        key: null,
        requireToUpgrade: true,
        hide: true,
      },
      {
        title: 'Customer Performance',
        link: '/tools/customer-performance',
        key: null,
        requireToUpgrade: true,
      },
      {
        title: 'Products Purchased',
        link: '/tools/products-purchased',
        key: null,
      },
    ],
  },
];

const customerMenuConfig: LinkItem[] = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    exact: true,
    icon: <SvgDashboardIcon />,
    key: null,
    permissionKey: 'dashboard',
  },
  {
    title: 'Browse by Category',
    link: '/categories',
    key: null,
    icon: <SvgCategoryIcon />,
  },
  {
    title: 'Browse by Supplier',
    link: '/browse_by_supplier',
    key: null,
    icon: <SvgCustomerIcon />,
  },
  {
    title: 'Pantry Lists',
    link: '/pantry_list',
    key: SelectedMenuDropDown.list,
    icon: <SvgListIcon />,
    nestedLinks: [
      {
        title: 'Pantry list',
        link: '/pantry_list',
        exact: true,
        key: null,
        permissionKey: 'pantry_lists',
      },
      {
        title: 'Recurring orders',
        link: '/pantry_list/recurring_orders',
        key: null,
      },
      {
        title: 'Preparation list',
        link: '/pantry_list/prep_lists',
        key: null,
        hide: process.env.REACT_APP_ENV === 'production',
      },
    ],
  },
  {
    title: 'Order History',
    link: '/orders_history',
    key: null,
    icon: <SvgHistoryIcon />,
    permissionKey: 'order_history',
  },
  {
    title: 'Menu Costing',
    link: '/menu_costing',
    key: null,
    icon: <SvgCalculateIcon />,
    permissionKey: 'menu_planning',
  },
  {
    title: 'Restaurant Pro Suite',
    link: '/tools',
    key: SelectedMenuDropDown.tools,
    icon: <SvgReportIcon />,
    nestedLinks: [
      {
        title: 'Automated Ordering',
        link: '/tools/automated_ordering',
        key: null,
        permissionKey: 'automated_ordering',
      },
      {
        title: 'Inventory',
        link: '/tools/inventory',
        key: null,
        permissionKey: 'inventory',
      },
      {
        title: 'Sales & Waste',
        link: '/tools/sales_wastage',
        key: null,
        permissionKey: 'sales_and_wastages',
      },
      {
        title: 'Reporting',
        link: '/tools/reports',
        key: null,
        permissionKey: 'reports',
      },
    ],
  },
  {
    title: 'Help',
    link: '/account/help/contact',
    key: null,
    icon: <SvgHelpIcon />,
  },
];

const customerMenuConfigNormalUser: LinkItem[] = [
  {
    title: 'Home',
    link: '/browse_by_supplier',
    exact: true,
    icon: <SvgDashboardIcon />,
    key: null,
  },
  {
    title: 'Pantry Lists',
    link: '/pantry_list',
    key: SelectedMenuDropDown.list,
    icon: <SvgListIcon />,
    nestedLinks: [
      {
        title: 'Pantry list',
        link: '/pantry_list',
        exact: true,
        key: null,
        permissionKey: 'pantry_lists',
      },
      {
        title: 'Recurring orders',
        link: '/pantry_list/recurring_orders',
        key: null,
      },
    ],
  },
  {
    title: 'Order History',
    link: '/orders_history',
    key: null,
    icon: <SvgHistoryIcon />,
  },
];

export const LeftNavBar: React.FC = () => {
  const { colors, logoUrl } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });
  const dispatch = useAppDispatch();
  const { isDesktop, isTablet, isMobile } = useScreenSize();
  const { push } = useHistory();

  const [selectedMenuDropDown, setSelectedMenuDropDown] = useState<SelectedMenuDropDown | null>();

  const { data: user } = useMeQuery();
  const prefetchNotification = usePrefetchNotificationSetting('getNotificationSetting');
  const plan = useAppSelector(getMySubscriptionType);

  const isMainMenuOpened = useAppSelector(getIsMainMenuOpened);
  const isAccountMenuOpened = useAppSelector(getIsAccountMenuOpened);
  const isRestaurant = useAppSelector(getIsRestaurant);

  const desktopForToolsModalAvailable = isMobile && !LocalStorageService.getItem('desktop_for_tools_shown');
  const { rpsOnboardingFinished } = useGetRestaurantRpsOnBoardingState();

  /*  const { connectionRequestsCount } = useGetConnectionRequestsQuery(undefined, {
    selectFromResult: ({ data = [] }) => ({
      connectionRequestsCount: data.filter((req) => req.status === CustomerConnectionStatus.PENDING && req.send_by === Roles.SUPPLIER)
        .length,
    }),
    skip: !isRestaurant,
  });*/

  const { customersCount } = useGetCustomersWithoutPriceListsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      customersCount: data?.customers?.length,
    }),
    skip: isRestaurant,
  });

  const { data: orders } = useGetOrdersDashboardQuery(undefined, {
    skip: isRestaurant,
  });

  const openUseDesktopForToolsModal = () => {
    dispatch(openUseDesktopForTools(true));
    collapseHandler(SelectedMenuDropDown.tools);
  };

  const toolsLinkHandler = () => {
    if (!rpsOnboardingFinished && plan === SubscriptionPlanEnum.HOSPO) {
      push('/tools/onboarding');
      setSelectedMenuDropDown(SelectedMenuDropDown.tools);
    } else {
      desktopForToolsModalAvailable && openUseDesktopForToolsModal();
      collapseHandler(SelectedMenuDropDown.tools);
    }
    if (plan !== SubscriptionPlanEnum.HOSPO) {
      push('/tools/restaurantpro');
    }
  };

  const collapseHandler = useCallback(
    (item: SelectedMenuDropDown | null): void => {
      setSelectedMenuDropDown(selectedMenuDropDown === item ? null : item);
    },
    [selectedMenuDropDown],
  );

  const profileHandler = () => {
    if (isAccountMenuOpened) {
      dispatch(closeAccountMenu());
    } else {
      dispatch(openMainMenu());
      dispatch(openAccountMenu());
    }
  };

  const hamburgerHandler = () => {
    if (isAccountMenuOpened || isMainMenuOpened) {
      dispatch(closeAccountMenu());
      dispatch(closeMainMenu());
    } else {
      dispatch(openMainMenu());
    }
  };

  const closeMenus = useCallback(() => {
    if (isDesktop && !isRestaurant) return;
    isAccountMenuOpened && dispatch(closeAccountMenu());
    isMainMenuOpened && dispatch(closeMainMenu());
  }, [isAccountMenuOpened, isMainMenuOpened]);

  const preventLinkAction = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  }, []);

  const onLogoClick = () => {
    setGtmDatalayer({
      event: 'header',
      eventCategory: 'logo_click',
    });
  };

  useEffect(() => {
    setSelectedMenuDropDown(null);
  }, [isMainMenuOpened]);

  useEffect(() => {
    isAccountMenuOpened && prefetchNotification();
  }, [isAccountMenuOpened]);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.rootCustomer]: isRestaurant,
        [classes.rootCollapsed]: !isMainMenuOpened,
        [classes.rootCollapsedCustomer]: isRestaurant && !isMainMenuOpened,
      })}
    >
      {!isMobile && !isRestaurant && (
        <div className={clsx([classes.top, !isMainMenuOpened && classes.topCollapsed])}>
          {isTablet && <CustomMenuButton isActive={isMainMenuOpened} onClick={hamburgerHandler} />}
          <NavLink
            to={'/dashboard'}
            className={clsx({
              [classes.topLinkCollapsed]: !isMainMenuOpened && isTablet,
              [classes.topLink]: isMainMenuOpened && isTablet,
            })}
          >
            {logoUrl ? (
              isDesktop || isMainMenuOpened ? (
                <img src={logoUrl} alt='logo' height={70} style={{ objectFit: 'contain', maxWidth: 200 }} />
              ) : null
            ) : (
              <img
                src={isDesktop ? OPLogo : isTablet && isMainMenuOpened ? LogoTablet : LogoSmall}
                alt='OpenPantryLogo'
                onClick={onLogoClick}
              />
            )}
          </NavLink>
        </div>
      )}
      <div
        className={clsx(
          classes.linksBox,
          isRestaurant && classes.linksBoxCustomer,
          isAccountMenuOpened && !isDesktop && classes.transparent,
        )}
      >
        {(isRestaurant
          ? plan === SubscriptionPlanEnum.NORMAL
            ? customerMenuConfigNormalUser
            : customerMenuConfig
          : supplierMenuConfig
        ).map(({ title, link, exact, nestedLinks, icon, key, showCounter, permissionKey }) => {
          return (
            <NavLinkItem
              isRestaurant={isRestaurant}
              key={title}
              link={link}
              title={title}
              exact={exact}
              icon={icon}
              permissionKey={permissionKey}
              expanded={selectedMenuDropDown === key}
              isMainMenuOpened={isMainMenuOpened}
              onClickHandler={link === '/tools' ? toolsLinkHandler : nestedLinks?.length ? collapseHandler.bind(null, key) : closeMenus}
              nestedLinks={(nestedLinks || []).filter(link => !link.hide)}
              nestedHandler={isRestaurant || !isDesktop ? closeMenus : () => {}}
              counter={
                showCounter
                  ? {
                      [link]:
                        link === '/orders'
                          ? orders?.total_new_orders || undefined
                          : link === '/customers'
                          ? customersCount || undefined
                          : undefined,
                    }
                  : undefined
              }
            />
          );
        })}
      </div>
      <div
        className={clsx({
          [classes.profileWrapper]: true,
          [classes.profileWrapBg]: isMainMenuOpened || isMobile,
          [classes.profileWrapperOpened]: isAccountMenuOpened,
          [classes.profileWrapperClosed]: !isMainMenuOpened,
          [classes.profileWrapperClosedRestaurant]: !isMainMenuOpened && isRestaurant,
        })}
      >
        <div
          className={clsx([classes.profileMenuBox, isAccountMenuOpened && classes.profileMenuBoxOpened])}
          onClick={!isDesktop ? profileHandler : undefined}
        >
          <NavLink
            to='/account'
            activeClassName={classes.profileLogoActive}
            className={classes.profileLogoLink}
            onClick={preventLinkAction}
          >
            {!isRestaurant && user?.company?.picture_url ? (
              <img src={user.company.picture_url} alt='Logo' className={classes.profileLogo} />
            ) : (
              <SvgProfileIcon className={classes.profileIcon} color={colorVariables.grey60} width={25} height={25} />
            )}
          </NavLink>
          <div
            className={clsx({
              [classes.profileText]: true,
              [classes.profileTextCollapsed]: !isMainMenuOpened,
              [classes.profileTextCustomer]: isRestaurant,
            })}
          >
            {!isRestaurant ? user?.company?.name || '' : `${user?.first_name || ''} ${user?.last_name || ''}`}
          </div>
          {isMainMenuOpened ? (
            isAccountMenuOpened ? (
              <KeyboardArrowDownIcon className={classes.profileChevron} />
            ) : (
              <ChevronRightIcon className={classes.profileChevron} />
            )
          ) : null}
        </div>
        <NestedAccountMenu closeMenus={closeMenus} />
      </div>
    </div>
  );
};
