import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    optionTrigger: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      color: colorVariables.grey80,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    optionTriggerCustom: {
      '& span': {
        color: colorVariables.blue,
      },
    },
    lockIcon: {
      color: colorVariables.grey80,
      opacity: 1,
      fontSize: 16,
      position: 'relative',
      top: '-8px',
    },
    lockIconWrap: {
      height: 25,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginLeft: 'auto',
    },
    permissionOption: {
      display: 'flex',
    },
  }),
);
