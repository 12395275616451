import React, { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { useUpdateTeammateMutation } from '../../../../api/teammates';
import { Teammate, TeammatePermission } from '../../../../api/teammates/types';
import { DropdownMenu } from '../../../../shared/components/dropdown-menu';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setCustomTeammatePermissionModal } from '../../../../store/teammates/teammates.actions';

import { useStyles } from './style';
import { UpgradeSubscriptionTooltip } from '../../../../shared/components/upgrade-subscription-tooltip';
import SvgLockIcon from '../../../../assets/icons/LockIcon';
import { getMySubscriptionType } from '../../../../store/user';
import { SubscriptionPlanEnum } from '../../../../api/user/types';

interface Props {
  item: Teammate;
  setPermission?: (option: TeammatePermission) => void;
}

export const SubmitPermissionDropdown: React.FC<Props> = ({ item, setPermission }) => {
  const classes = useStyles();
  const [currentPermission, setCurrentPermission] = useState<TeammatePermission>(item.permission);

  const [updateTeammate] = useUpdateTeammateMutation();
  const dispatch = useAppDispatch();
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const isNormalUser = (subscriptionType === SubscriptionPlanEnum.FREE || subscriptionType === SubscriptionPlanEnum.NORMAL) 

  const onSubmitPermission = (permission: TeammatePermission) => {
    if(isNormalUser && permission !== TeammatePermission.VIEWER) {
      return;
    }

    if (permission === TeammatePermission.CUSTOM) {
      dispatch(setCustomTeammatePermissionModal(item.id));
      return;
    }
    setCurrentPermission(permission);
    if (setPermission) {
      setPermission(permission);
    } else {
      updateTeammate({
        id: item.id,
        permission,
        teammate: {
          first_name: item.first_name,
          email: item.email,
        },
      });
    }
  };

  useEffect(() => {
    setCurrentPermission(item.permission);
  }, [item.permission]);

  return (
    <DropdownMenu
      disabledIdx={isNormalUser ? [1, 2, 3] : []}
      items={[
        <div key='View' onClick={onSubmitPermission.bind(null, TeammatePermission.VIEWER)}>
          Viewer
        </div>,
        <div key='Editor' onClick={onSubmitPermission.bind(null, TeammatePermission.EDITOR)} className={clsx(classes.permissionOption)}>
          Editor
          {isNormalUser && <div className={classes.lockIconWrap}>
            <SvgLockIcon className={classes.lockIcon} />
          </div>}
        </div>,
        <div key='Admin' onClick={onSubmitPermission.bind(null, TeammatePermission.ADMIN)} className={clsx(classes.permissionOption)}>
          Admin
          {isNormalUser && <div className={classes.lockIconWrap}>
            <SvgLockIcon className={classes.lockIcon} />
          </div>}
        </div>,
        <div key='Custom Mode' onClick={onSubmitPermission.bind(null, TeammatePermission.CUSTOM)} className={clsx(classes.permissionOption)}>
          Custom Mode
          {isNormalUser && <div className={classes.lockIconWrap}>
            <SvgLockIcon className={classes.lockIcon} />
          </div>}
        </div>,
      ]}
      triggerBtn={
        <div className={clsx(classes.optionTrigger, currentPermission === TeammatePermission.CUSTOM && classes.optionTriggerCustom)}>
          <span>{currentPermission}</span>
          <KeyboardArrowDownIcon />
        </div>
      }
    />
  );
};
