// export const parseFloatFromString = (str?: string | null) => {
//   if (!str) {
//     return 0;
//   }
//   const cleanedInput = str.replace(/[A-Za-z]+/g, '');
//   const regex = /[+-]?(?:\d+)?(?:[.,])?(?:\d+)?/;
//   const match = cleanedInput.trim().match(regex);
//   return match ? Math.round(parseFloat(match[0].replace(',', '.')) * 100) / 100 : 0;
// };

export const getSymbol = (value: string) => {
  let match = value.match(/^\D/);
  return match ? match[0] : '$';
}

export const parseFloatFromString = (str?: string | null) => {
  if (!str) {
    return 0;
  }
  // Remove all non-numeric characters except '.', ',', '+', and '-'
  const cleanedInput = str.replace(/[^0-9.,+-]/g, ''); 
  const regex = /[+-]?\d+(?:[.,]\d+)?/;
  const match = cleanedInput.match(regex);
  return match ? Math.round(parseFloat(match[0].replace(',', '.')) * 100) / 100 : 0;
};