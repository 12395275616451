import { TokenService } from '../../shared/services/token.service';
import { clearProductsList } from '../../store/products';
import { setAppSuccessToast } from '../../store/user';
import { setSuccessMsgHelper } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { userApi } from '../user';
import { ConnectionRequest, SubscriptionPlanEnum } from '../user/types';
import {
  AcceptConnectionRequestBody,
  AddAlternateEmailRequest,
  AddManualSupplierRes,
  Company,
  DeclineConnectionRequestBody,
  GetPostCodesResponse,
  SendConnectionRequestBody,
  SetDeliveryDayByPostCodeReq,
  SetDeliveryFeeByDayReq,
  UpdateConnectionRequestBody,
  UpdateRestaurantRequest,
  UpdateSupplierProfileRequest,
} from './types';
import { RootState } from '../../store';
import { LocalStorageService } from '../../shared/services/localStorage.service';

export const companyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRestaurantProfile: builder.mutation<SuccessResponse, UpdateRestaurantRequest>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        const { id, company } = body;
        company.users_attributes.id = userId;
        return {
          url: `/users/${userId}/companies/${id}`,
          method: HTTP.PATCH,
          body: {
            company,
          },
        };
      },
      invalidatesTags: ['Me', 'Company', 'Menu'],
    }),

    getMyCompany: builder.query<Company, number | void>({
      query(id) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/companies/${id || ''}`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Company'],
    }),

    getFreeDeliveryAt: builder.query<{ fee_delivery_at: number, amount_for_free_delivery_enabled: boolean }, void>({
      query() {
        return {
          url: `/free_delivery_at`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Company'],
    }),

    updateFreeDeliveryAt: builder.mutation<SuccessResponse, {amount?: number, enabled?: boolean}>({
      query({amount = null, enabled = null}) {
        return {
          url: `/update_fee_delivery_at`,
          method: HTTP.PATCH,
          body: {
            amount,
            free_delivery_at_enabled: enabled
          },
        };
      },
      invalidatesTags: ['Company'],
    }),

    updateAbn: builder.mutation<SuccessResponse, string>({
      query(abn_number) {
        return {
          url: `/update_abn_number`,
          method: HTTP.PATCH,
          body: { abn_number },
        };
      },
      invalidatesTags: ['Me'],
    }),

    setDeliveryFeeByDay: builder.mutation<SuccessResponse, SetDeliveryFeeByDayReq>({
      query(body) {
        return {
          url: `/set_delivery_fee_by_day`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),

    setDeliveryDayByPostCode: builder.mutation<SuccessResponse, SetDeliveryDayByPostCodeReq>({
      query(body) {
        return {
          url: `/set_delivery_day_by_postcode`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Postcode'],
    }),

    uploadPostcodes: builder.mutation<SuccessResponse, FormData>({
      query(body) {
        return {
          url: `/upload_postcodes`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await setSuccessMsgHelper(dispatch, queryFulfilled, data?.message || 'Uploaded');
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Postcode'],
    }),

    getPostcodes: builder.query<GetPostCodesResponse, { current_postcode?: boolean }>({
      query(params) {
        return {
          url: `/view_postcodes`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Postcode'],
    }),

    deletePostCodes: builder.mutation<SuccessResponse, { ids: number[] }>({
      query(body) {
        return {
          url: `/delete_postcodes`,
          method: HTTP.DELETE,
          body,
        };
      },
      invalidatesTags: ['Postcode'],
    }),

    excludePostCodes: builder.mutation<SuccessResponse, { postcodes: string }>({
      query(body) {
        return {
          url: `/exclude_postcodes`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Postcode'],
    }),

    createPostCodes: builder.mutation<SuccessResponse, { postcodes: string }>({
      query(body) {
        return {
          url: `/create_multiple_postcode`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Postcode'],
    }),

    contactSales: builder.query<SuccessResponse, void>({
      query() {
        return {
          url: `/contact_sales`,
          method: HTTP.GET,
        };
      },
    }),

    updateSupplierProfile: builder.mutation<SuccessResponse, UpdateSupplierProfileRequest>({
      query({ id, formData: body }) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/companies/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Me', 'Company'],
    }),

    getConnectionRequests: builder.query<ConnectionRequest[], void>({
      query() {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/connection_requests`,
          method: HTTP.GET,
          params: {
            customer_id: LocalStorageService.getItem('impersonated_customer')?.customer_id
          }
        };
      },
      providesTags: ['Connection'],
    }),

    sendConnectionRequest: builder.mutation<SuccessResponse, SendConnectionRequestBody>({
      query({ showSuccessModal, ...body }) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/suppliers/${body.connection_request?.supplier_id || ''}/send_connection_request`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch }) {
        if (args.showSuccessModal) {
          dispatch(setAppSuccessToast('Request to connect sent'));
        }
      },
      invalidatesTags: ['Connection', 'Supplier'],
    }),

    acceptConnectionRequest: builder.mutation<SuccessResponse, AcceptConnectionRequestBody>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/accept_connect_request`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(arg, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if(!arg.disable_alert) {
            dispatch(setAppSuccessToast('Accepted new connection request'));
          }
          if ((getState() as RootState)?.user?.subscriptionType !== SubscriptionPlanEnum.NORMAL) {
            dispatch(clearProductsList());
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Connection', 'Supplier', 'Customers_Count', 'Customer', 'Menu', 'Recipe', 'Recipes'],
    }),

    declineConnectionRequest: builder.mutation<SuccessResponse, DeclineConnectionRequestBody>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/decline_connect_request`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if ((getState() as RootState)?.user?.subscriptionType !== SubscriptionPlanEnum.NORMAL) {
            dispatch(clearProductsList());
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Connection', 'Supplier', 'Menu', 'Recipe', 'Recipes'],
    }),

    updateConnectionRequest: builder.mutation<SuccessResponse, UpdateConnectionRequestBody>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/update_connect_request`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if ((getState() as RootState)?.user?.subscriptionType !== SubscriptionPlanEnum.NORMAL) {
            dispatch(clearProductsList());
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Connection', 'Supplier', 'Menu', 'Recipe', 'Recipes'],
    }),

    changeHolidaysDelivery: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/change_delivery_holiday`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            draft.holidays_delivery = !draft.holidays_delivery;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    changeApplyDeliveryFeeByDay: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/change_apply_delivery_fee_by_day`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            if('delivery_fee_by_day' in draft) {
              draft.delivery_fee_by_day.apply_delivery_fee = !draft.delivery_fee_by_day.apply_delivery_fee;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteHolidays: builder.mutation<SuccessResponse, number[]>({
      query(args) {
        return {
          url: `/delete_holiday_dates`,
          method: HTTP.DELETE,
          body: {
            ids: args,
          },
        };
      },
      invalidatesTags: ['Me'],
    }),

    addHolidays: builder.mutation<SuccessResponse, string>({
      query(args) {
        return {
          url: `/create_holiday_dates`,
          method: HTTP.POST,
          body: {
            dates: args,
          },
        };
      },
      invalidatesTags: ['Me'],
    }),

    addAlternateEmail: builder.mutation<SuccessResponse, AddAlternateEmailRequest>({
      query({ user_id, ...body }) {
        return {
          url: `/users/${user_id}/invite_by_alternate`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch }) {
        dispatch(setAppSuccessToast('Invite has been sent'));
      },
    }),

    onboardManualSupplier: builder.mutation<AddManualSupplierRes, FormData>({
      query: (body) => {
        return {
          url: `/manual_supplier`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Connection', 'Supplier', 'Me'],
    }),

    editManualSupplier: builder.mutation<AddManualSupplierRes, FormData>({
      query: (body) => {
        return {
          url: `/edit_manual_supplier`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Me', 'Supplier', 'Connection'],
    }),

    inviteManualSupplier: builder.mutation<AddManualSupplierRes, FormData>({
      query: (body) => {
        return {
          url: `/manual_supplier`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Connection', 'Supplier', 'Me', 'Menu', 'Recipe', 'Recipes'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateRestaurantProfileMutation,
  useGetMyCompanyQuery,
  useUpdateSupplierProfileMutation,
  useGetConnectionRequestsQuery,
  useSendConnectionRequestMutation,
  useAcceptConnectionRequestMutation,
  useDeclineConnectionRequestMutation,
  useUpdateConnectionRequestMutation,
  usePrefetch,
  useGetFreeDeliveryAtQuery,
  useUpdateFreeDeliveryAtMutation,
  useUploadPostcodesMutation,
  useGetPostcodesQuery,
  useSetDeliveryFeeByDayMutation,
  useSetDeliveryDayByPostCodeMutation,
  useDeletePostCodesMutation,
  useCreatePostCodesMutation,
  useExcludePostCodesMutation,
  useLazyContactSalesQuery,
  useUpdateAbnMutation,
  useChangeHolidaysDeliveryMutation,
  useChangeApplyDeliveryFeeByDayMutation,
  useDeleteHolidaysMutation,
  useAddHolidaysMutation,
  useAddAlternateEmailMutation,
  useOnboardManualSupplierMutation,
  useInviteManualSupplierMutation,
  useEditManualSupplierMutation,
} = companyApi;
