import React, { useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateRestaurantProfileMutation } from '../../../api/company';
import { useGeIsTeammateViewer } from '../../../api/teammates/hooks';
import { useDeleteAccountMutation, useMeQuery, useUpdatePasswordMutation } from '../../../api/user';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { InfoTooltip } from '../../../shared/components/info-tooltip';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { setGtmDatalayer } from '../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { openAccountMenu, openMainMenu } from '../../../store/user';
import { getIsRestaurant } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';
import SvgTrash from '../../../assets/icons/Trash';
import { Dialog } from '../../../shared/components/dialog';

interface BasicInfoValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  accountsEmail?: string;
}

interface ChangePasswordValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export const CustomerProfile: React.FC = () => {
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();
  const dispatch = useAppDispatch();
  const passwordRef = useRef<HTMLFormElement | null>(null);
  const [isDeleteAccountOpened, setIsDeleteAccountOpened] = useState(false);
  const [myEmail, setMyEmail] = useState('');
  const [emailToChange, setEmailToChange] = useState('');
  const isViewer = useGeIsTeammateViewer();

  const isRestaurant = useAppSelector(getIsRestaurant);

  const [deleteUser, { isLoading: deletingUser }] = useDeleteAccountMutation();
  const [updateProfile, { isLoading, isSuccess: updateSuccess }] = useUpdateRestaurantProfileMutation();
  const [updatePassword, { isSuccess: isPasswordUpdated, isLoading: isPasswordLoading, error: updatePasswordError }] =
    useUpdatePasswordMutation();
  const { data: user } = useMeQuery();

  const basicSchema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Enter correct email!'),
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
    accountsEmail: Yup.string().email('Enter correct email!'),
  });

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required field'),
    newPassword: Yup.string().required('Required field').min(8, 'Too short'),
    confirmPassword: Yup.string()
      .required('Required field')
      .oneOf([Yup.ref('newPassword'), null], 'Does not match'),
  });

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmit = (values: BasicInfoValues, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setMyEmail(user?.email || '');
    setEmailToChange(values.email);

    setGtmDatalayer({
      event: 'profile',
      eventCategory: 'update',
    });
    if (!user) {
      return;
    }
    updateProfile({
      id: user.company.id,
      company: {
        name: values.company,
        users_attributes: {
          first_name: values.firstName,
          last_name: values.lastName,
          phone_number: values.phone,
          email: values.email,
          account_email: values.accountsEmail,
        },
      },
    });
  };

  const onChangePasswordSubmit = (values: ChangePasswordValues, formikHelpers: FormikHelpers<ChangePasswordValues>) => {
    updatePassword({
      user: {
        current_password: values.password,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      },
    });
    formikHelpers.setSubmitting(false);
  };

  const onDeleteAccount = useCallback( () => {
    deleteUser();
    setIsDeleteAccountOpened(false);
  }, []);

  useEffect(() => {
    isPasswordUpdated && passwordRef.current?.reset();
  }, [isPasswordUpdated]);

  useEffect(() => {
    if (updateSuccess) {
      ToastService.success('Profile Updated');
    }
    if (updateSuccess && emailToChange && myEmail !== emailToChange) {
      ToastService.success('We have sent you an email to confirm your email change');
      setMyEmail('');
      setEmailToChange('');
    }
  }, [updateSuccess]);

  if (!user) {
    return null;
  }

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={isMobile ? <NavbarTitle title='Profile' showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            {isRestaurant && isTablet && (
              <div className={classes.tabletTitleCustomer}>
                <NavbarTitle title='Profile' showBackBtn={true} backHandler={openMenus} />
              </div>
            )}
            <div className={classes.titleBox}>
              <h2 className={classes.title}>Profile</h2>
            </div>
            <div className={classes.basicInfo}>
              <h4 className={classes.sectionTitle}>Basic information</h4>
              <Formik
                initialValues={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                  phone: user.phone_number || '',
                  email: user.email,
                  company: user.company.name,
                  accountsEmail: user.account_email || '',
                }}
                onSubmit={onSubmit}
                validationSchema={basicSchema}
              >
                {({ submitForm }) => (
                  <Form className={classes.formBox}>
                    <div className={classes.fieldsBox}>
                      <div className={classes.fieldWrap}>
                        <Field name='company'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='Company name*' placeholder='Company name' disabled={isViewer} />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='phone'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='number'
                              label='Phone Number'
                              placeholder='Phone Number '
                              disabled={isViewer}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='firstName'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='First name*' placeholder='First name' disabled={isViewer} />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='lastName'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='Last name*' placeholder='Last name' disabled={isViewer} />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='email'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='Email Address*' placeholder='Email Address' disabled={isViewer} />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <div className={classes.tooltipBox}>
                          <Field name='accountsEmail'>
                            {(fieldProps: FieldProps) => (
                              <FormikInput {...fieldProps} label='Accounts Email' placeholder='Accounts Email' disabled={isViewer} />
                            )}
                          </Field>
                          <div className={classes.tooltipWrap}>
                            <InfoTooltip text='A copy of your invoices will be sent to this email address' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ThemedButton title='Update' onClick={submitForm} width={280} customClass={classes.submitBtn} disabled={isLoading} />
                  </Form>
                )}
              </Formik>
            </div>
            <div className={classes.changePassword}>
              <h4 className={classes.sectionTitle}>Change password</h4>
              <Formik
                initialValues={{
                  password: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={changePasswordSchema}
                onSubmit={onChangePasswordSubmit}
              >
                {({ submitForm }) => (
                  <Form className={classes.formBox} ref={passwordRef}>
                    <div className={classes.fieldsBox}>
                      <div className={classes.fieldWrap}>
                        <Field name='password'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='password'
                              label='Verify password'
                              placeholder='Verify password'
                              customError={
                                updatePasswordError && 'data' in updatePasswordError ? updatePasswordError.data.message : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='newPassword'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='password' label='New password' placeholder='New password' />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='confirmPassword'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='password' label='Confirm new password' placeholder='Confirm new password' />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ThemedButton
                      title='Update'
                      onClick={submitForm}
                      width={280}
                      customClass={classes.submitBtn}
                      disabled={isPasswordLoading}
                    />
                    <ThemedButton
                      onClick={setIsDeleteAccountOpened.bind(null, true)}
                      title={'Delete Account'}
                      buttonStyle='icon'
                      startIcon={<SvgTrash />}
                      disabled={deletingUser}
                      customClass={classes.submitBtn}
                    />
                    {isDeleteAccountOpened && (
                        <Dialog
                          title='Are you sure you want to delete this account?'
                          onConfirm={onDeleteAccount}
                          onCancel={setIsDeleteAccountOpened.bind(null, false)}
                          customClass={classes.dialog}
                        />
                      )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
