import { ProductsCategories } from '../../shared/constants/products';
import { CustomerConnectionStatus } from '../customer/types';
import { SuccessResponse } from '../index';
import { Menu } from '../menu/types';
import { Category } from '../product/types';
import { AutoCheckOffReqEnum, BaseUser, Roles, SupplierUser } from '../user/types';

export interface BaseCompany {
  id: number;
  name: string;
  code: string | null;
  bio: string | null;
  website: string | null;
  company_type: Roles;
  picture_url: string | null;
}

export interface Company extends BaseCompany {
  users: BaseUser[];
  suppliers: Array<BaseUser & Pick<SupplierUser, 'delivery_detail'>>;
  company_categories: CompanyCategories[];
  categories: Category[];
  contacts: Contact[];
  directors: any[];
  delivery_details: any[];
  addresses: Address[];
  menus: Omit<Menu, 'recipes'>[];
}

export interface DeliveryDetailsInUser {
  id: number | null;
  user_id: number | null;
  minimum_order_value_cents: number;
  delivery_fee_cents: number;
  delivery_fee_currency: string;
  amount_for_free_delivery_cents: number;
  amount_for_free_delivery_currency: string;
  delivery_monday: boolean;
  delivery_tuesday: boolean;
  delivery_wednesday: boolean;
  delivery_thursday: boolean;
  delivery_friday: boolean;
  delivery_saturday: boolean;
  delivery_sunday: boolean;
  accept_free_delivery: boolean;
  delivery_type: number;
  cutoff: string;
  company_id: number | null;
  top_up_orders: boolean;
}

export interface Address {
  id: number;
  apt_number: string | null;
  city: string | null;
  country: string | null;
  full_address: string | null;
  latitude: number | null;
  longitude: number | null;
  postcode: string | null;
  state: string | null;
  street_address1: string | null;
  street_address2: string | null;
  address_type: string | null;
  first_name: string | null;
  last_name: string | null;
  default_address: boolean;
  created_at?: string;
  addressable_id?: number;
}

export interface SupplierProfile {
  name: string;
  bio?: string;
  website?: string;
  picture_url?: string;
  addressId?: string;
  city?: string;
  country?: string;
  full_address?: string;
  postcode?: string;
  state?: string;
  street_address1?: string;
  street_address2?: string;
  delivery_detail_id?: number | null;
  min_order_value?: number;
  cutoff?: string;
  delivery_days: DeliveryDay[];
  categories: ProductsCategories[];
  delivery_radius?: number;
  delivery_radius_id?: number;
  delivery_fee?: number;
  first_name: string;
  last_name: string;
  email: string;
  contacts: Partial<Contact>[];
  office_email?: string;
  office_phone?: string;
  address?: Partial<Address>;
  image?: Blob;
  top_up_orders: boolean;
}

export interface UpdateSupplierProfileRequest {
  formData: FormData;
  id: number;
  skipInvalidation?: boolean;
}

export interface UpdateRestaurantRequest {
  id: number;
  company: {
    name?: string;
    users_attributes: {
      id?: number;
      first_name?: string;
      last_name?: string;
      phone_number?: string;
      email?: string;
      account_email?: string;
      auto_check_off?: AutoCheckOffReqEnum | null;
      payment_method_type?: 1 | 0;
      sub_recipe_video?: boolean;
    };
    company_categories_attributes?: {
      id?: number;
      category_id?: number;
      _destroy?: boolean;
    }[];
    menus_attributes?: {
      name: string;
    }[];
  };
}

export enum DeliveryDay {
  MONDAY = 'delivery_monday',
  TUESDAY = 'delivery_tuesday',
  WEDNESDAY = 'delivery_wednesday',
  THURSDAY = 'delivery_thursday',
  FRIDAY = 'delivery_friday',
  SATURDAY = 'delivery_saturday',
  SUNDAY = 'delivery_sunday',
}

export interface CompanyCategories {
  id: number;
  category_id: number;
  company_id: number;
}

export enum ContactType {
  ORDER = 'order_contact',
  OFFICE = 'office',
}

export interface Contact {
  id: number;
  contact_type: ContactType;
  email: string | null;
  name: string | null;
  phone_number: string | null;
  company_id: number;
  is_order_email: boolean;
}

export interface SendConnectionRequestBody {
  showSuccessModal?: boolean;
  connection_request: {
    supplier_id: number;
    connection_categories_attributes: [
      {
        category_id?: number;
      },
    ];
  };
}

export interface AcceptConnectionRequestBody {
  connection_request: {
    id: number;
    status?: CustomerConnectionStatus;
    connection_categories_attributes?: {
      id?: number;
      category_id: number;
      _destroy?: boolean;
    }[];
  };
  price_list_id?: number;
  disable_alert?: boolean;
}

export interface DeclineConnectionRequestBody {
  connection_request_id: number;
}

export interface UpdateConnectionRequestBody {
  connection_request: {
    id: number;
    connection_categories_attributes: {
      id?: number;
      category_id: number;
      _destroy?: boolean;
    }[];
  };
}

export interface GetPostCodesResponse extends SuccessResponse {
  postcodes_count: number;
  postcodes: PostCode[];
}

export interface PostCode {
  id: number;
  area_code: string;
  current_postcode: boolean;
  post_code_delivery_day: {
    id: number;
    delivery_friday: boolean;
    delivery_monday: boolean;
    delivery_saturday: boolean;
    delivery_sunday: boolean;
    delivery_thursday: boolean;
    delivery_tuesday: boolean;
    delivery_wednesday: boolean;
  };
}

export interface SetDeliveryFeeByDayReq {
  delivery_fee_by_day: {
    [key: string]: number;
  };
}

export interface SetDeliveryDayByPostCodeReq {
  delivery_postcode_id: number;
  post_code_delivery_day: {
    delivery_friday?: boolean;
    delivery_monday?: boolean;
    delivery_saturday?: boolean;
    delivery_sunday?: boolean;
    delivery_thursday?: boolean;
    delivery_tuesday?: boolean;
    delivery_wednesday?: boolean;
  };
}

export interface AddAlternateEmailRequest {
  connection_id: string;
  password?: string;
  email: string;
  user_id: string;
}

export interface AddManualSupplierRes extends SuccessResponse {
  company: Company;
}
