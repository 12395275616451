import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 20,
    },
    advancedSectionHeadText: {
      marginTop: 5,
      fontSize: 13,
      color: '#5B6578',
      marginBottom: 15,
    },
    subTitleText: {
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      textTransform: 'uppercase',
      marginRight: 15,
    },
    subTitleTextWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    uploadContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      marginBottom: 20,
      height: 156,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.tablet)]: {
        height: 128,
        marginBottom: 10,
      },
    },
    focused: {
      border: `2px dashed ${colorVariables.green}`,
    },
    uploadIcon: {
      width: 30,
      height: 30,
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 15,
      fontWeight: 400,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 10,
        fontSize: 12,
      },
    },
    uploadDescriptionBtn: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    postCodeViewBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'block',
      },
    },

    postCodeView: {
      width: '50%',
      padding: '20px 20px 20px 0',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
        padding: '20px 0',
      },
    },
    borderBox: {
      borderLeft: `1px solid ${colorVariables.steelGrey}`,
      paddingLeft: 20,
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingLeft: 0,
        borderLeft: 'none',
      },
    },
    postCodeViewHead: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    addBtn: {
      cursor: 'pointer',
      color: colorVariables.blue,
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 700,
      },
    },
    addBtnHolidays: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'row-reverse',
        transform: 'translateY(-35px)',
      },
    },
    addIcon: {
      marginRight: 7,
      fontSize: 24,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 0,
        marginLeft: 5,
        fontSize: 22,
      },
    },
    postCodesList: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'hidden',
      height: 72,
      marginTop: 38,
      lineHeight: '24px',
      fontSize: 16,
      color: '#5B6578',
      '& span': {
        display: 'inline-block',
        width: '12%',
        textAlign: 'center',
        overflow: 'hidden',
      },
      '& b': {
        position: 'absolute',
        right: 10,
        bottom: 0,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 56,
        '& span': {
          width: '14%',
        },
        '& b': {
          right: 2,
        },
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginTop: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        '& span': {
          width: '20%',
        },
        '& b': {
          right: -2,
        },
      },
    },
    excludedCodesList: {
      color: '#EA6363',
    },
    postCodesListBtnBox: {
      display: 'flex',
      marginTop: 18,
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'space-between',
      },
    },
    viewAllBtn: {
      height: 30,
      padding: 0,
      fontSize: 14,
      marginRight: 15,
    },
    postcodesCount: {
      fontSize: 11,
      lineHeight: '30px',
      fontStyle: 'italic',
      color: colorVariables.grey60,
    },
    weekBlock: {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'space-between',
    },
    weekDay: {
      flex: 1,
      margin: '0 5px',
      textAlign: 'center',
      '& .MuiInput-root': {
        height: 32,
        fontSize: 14,
        borderRadius: '0px !important',
        padding: '5px 2px !important',
        '& input': {
          textAlign: 'center !important',
          color: colorVariables.userNavy,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: '0 3px',
      },
    },
    weekDayTitle: {
      color: colorVariables.userNavy,
      marginBottom: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 12,
      },
    },
    weekDayTitleDisabled: {
      color: colorVariables.grey60,
    },
    postCodeDeliveryWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    postcodeDropdown: {
      width: 80,
      flexShrink: 0,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 120,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 80,
      },
    },
    weeksBlockPostcodes: {
      width: 'calc(100% - 85px)',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 'calc(100% - 125px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 85px)',
      },
    },
    radioBox: {
      height: 32,
      paddingTop: 8,
    },
    radioBtn: {
      width: 16,
      height: 16,
      flexShrink: 0,
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${colorVariables.grey60}`,
      borderRadius: '50%',
      cursor: 'pointer',
      '&>div': {
        display: 'none',
      },
    },
    radioBtnActive: {
      border: `2px solid ${colorVariables.navy}`,
      '&>div': {
        display: 'block',
        width: 7,
        height: 7,
        backgroundColor: colorVariables.navy,
        borderRadius: '50%',
      },
    },
    viewAllModal: {
      padding: '45px 30px 20px 30px',
      width: 800,
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '45px 25px 20px 25px',
        width: 600,
      },
    },
    viewAllContent: {
      marginBottom: 20,
    },
    viewAllBtnBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    viewAllTitle: {
      fontSize: 20,
      fontWeight: 700,
      marginBottom: 10,
    },
    viewAllHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: 30,
    },
    addHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 30,
      paddingTop: 10,
    },
    allCodesText: {
      fontWeight: 700,
      marginRight: 15,
    },
    viewAllContentHead: {
      height: 46,
      padding: 15,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      color: colorVariables.grey60,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
    },
    postCodesTable: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      height: '60vh',
      overflowY: 'scroll',
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: '45vh',
      },
    },
    postCodeTableCell: {
      minWidth: 50,
      width: '12.5%',
      lineHeight: '20px',
      textAlign: 'right',
      paddingRight: 30,
      margin: '8px 0',
      position: 'relative',
      flexShrink: 0,
      overflow: 'hidden',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '16.5%',
      },
    },
    postCodeTableCellInvalid: {
      color: colorVariables.red,
      overflow: 'visible',
    },
    closeIconWrap: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      border: '1px solid #DADADA',
      cursor: 'pointer',
      position: 'absolute',
      right: 5,
      top: 1,
      backgroundColor: colorVariables.white,
      boxShadow: '0 0 0 6px #ffffff',
      '&:hover': {
        border: `1px solid ${colorVariables.grey60}`,
      },
      '&:hover svg': {
        color: colorVariables.grey60,
      },
    },
    closeIcon: {
      color: '#DADADA',
      fontSize: 14,
    },
    addTitle: {
      fontSize: 20,
      fontWeight: 700,
    },
    addSubTitle: {
      color: colorVariables.grey80,
    },
    postCodesTotal: {
      transform: 'translateY(-25px)',
    },
    addBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 27,
      borderTop: `1px solid ${colorVariables.steelGrey}`,
    },
    addBox: {
      borderRadius: 20,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      padding: 30,
      marginBottom: 15,
      display: 'flex',
      alignItems: 'flex-end',
    },
    inputWrap: {
      width: '60%',
      marginRight: 20,
    },
    addBoxBtns: {
      paddingBottom: 7,
    },
    addedCodesList: {
      padding: '10px 0',
      display: 'flex',
      flexWrap: 'wrap',
    },
    addedCodesListScrolled: {
      height: 300,
      overflowY: 'scroll',
    },
    removeWarning: {
      bottom: 0,
      right: 0,
    },
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
    holidaysBlock: {
      paddingTop: 40,
      display: 'flex',
      alignItems: 'center',
    },
    holidaysLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 700,
      lineHeight: '24px',
      marginRight: 15,
    },
    holidaysContent: {
      paddingTop: 5,
    },
    holidaysContentHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
    },
    holidaysTitle: {
      fontSize: 13,
      color: colorVariables.grey80,
      width: 290,
    },
    dateItem: {
      fontSize: 16,
      color: colorVariables.grey80,
      width: 50,
      display: 'inline-block',
      marginRight: 7,
      marginBottom: 7,
    },
    holidaysModal: {
      padding: '45px 30px 20px 30px',
      width: 820,
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '45px 25px 20px 25px',
        width: 600,
      },
    },
    holidaysModalHead: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: 50,
    },
    holidaysModalTitle: {
      fontSize: 20,
      fontWeight: 700,
    },
    holidaysSubtitle: {
      fontSize: 13,
      color: colorVariables.grey80,
      paddingLeft: 25,
    },
    holidaysModalContent: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 30,
      '&>div': {
        width: '47%',
      },
    },
    holidaysListHead: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
    },
    holidaysListTitle: {
      color: '#98A5B7',
      fontSize: 11,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    holidaysListBox: {
      backgroundColor: 'rgba(152, 165, 183, 0.04)',
      padding: 15,
      height: 305,
      overflowY: 'scroll',
    },
    holidaysBtns: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    holidaysCell: {
      position: 'relative',
      display: 'inline-flex',
      width: 65,
      height: 26,
      marginRight: 10,
    },
  }),
);
