import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateFreeDeliveryAtMutation, useUpdateSupplierProfileMutation } from '../../../../api/company';
import { SupplierProfile } from '../../../../api/company/types';
import { ButtonsBlock } from '../../../../shared/components/buttons-block';
import { FormikInput } from '../../../../shared/components/formik-input';
import { InfoTooltip } from '../../../../shared/components/info-tooltip';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { getCurrency } from '../../../../shared/helpers/getCurrency';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Values {
  delivery_radius?: number;
  free_delivery_at?: number | string;
  delivery_detail_id?: number;
  supplier_id?: number;
  delivery_radius_id?: number;
  delivery_fee?: number | string;
}

interface Props {
  values: Values;
  onClose: () => void;
  onSubmit: (data: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
  setFreeDelivery: (value: number) => void;
}

const DeliveryDetailsSchema = Yup.object().shape({
  delivery_radius: Yup.number().moreThan(-1, 'Should be positive'),
});

export const DeliveryDetailsForm: React.FC<Props> = ({
  values,
  companyId,
  onClose,
  onSubmit,
  isModalOpen,
  isOnBoarding,
  setNextStep,
  setFreeDelivery,
}) => {
  const classes = useStyles();
  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();
  const [updateFreeDeliveryAt] = useUpdateFreeDeliveryAtMutation();

  const ref = useRef<HTMLFormElement | null>(null);

  const onSubmitForm = ({ free_delivery_at, delivery_radius, delivery_fee }: Values, formikHelpers: FormikHelpers<Values>) => {
    if (isOnBoarding) {
      onSubmit({
        delivery_radius,
        delivery_fee: delivery_fee ? +delivery_fee : 0,
      });
      setFreeDelivery(free_delivery_at ? +free_delivery_at * 100 : 0);
      setNextStep(ProfileModals.PRODUCT_TYPES);
    } else {
      updateFreeDeliveryAt({amount: free_delivery_at ? +free_delivery_at * 100 : 0});
      const formData = new FormData();
      values.delivery_radius !== delivery_radius &&
        setGtmDatalayer({
          event: 'profile',
          eventCategory: 'delivery_radius_update',
        });
      values.delivery_fee !== delivery_fee &&
        setGtmDatalayer({
          event: 'profile',
          eventCategory: 'delivery_fee_update',
        });
      values.supplier_id && formData.append('company[suppliers_attributes][0][id]', values.supplier_id.toString());
      values?.delivery_detail_id && formData.append('company[delivery_details_attributes][0][id]', values.delivery_detail_id.toString());
      values?.supplier_id && formData.append('company[delivery_details_attributes][0][user_id]', values.supplier_id.toString());
      formData.append('company[delivery_details_attributes][0][delivery_fee_cents]', ((delivery_fee ? +delivery_fee : 0) * 100).toString());
      values.delivery_radius_id &&
        formData.append('company[suppliers_attributes][0][delivery_radiuses_attributes][0][id]', values.delivery_radius_id.toString());
      formData.append(
        'company[suppliers_attributes][0][delivery_radiuses_attributes][0][delivery_radius]',
        (delivery_radius || 0).toString(),
      );
      companyId && updateProfile({ formData, id: companyId });
    }
    formikHelpers.setSubmitting(false);
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.DELIVERY_DAYS) : onClose();
  };

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  useEffect(() => {
    !isModalOpen && ref?.current?.reset();
  }, [isModalOpen]);

  return (
    <OverlayModal title='Edit delivery details' onClose={onClose} isOpen={isModalOpen} animate={!isOnBoarding}>
      <Formik initialValues={values} validationSchema={DeliveryDetailsSchema} onSubmit={onSubmitForm} enableReinitialize={true}>
        {({ submitForm }) => (
          <Form className={classes.formContainer} ref={ref}>
            <div className={classes.fieldWrap}>
              <Field
                component={FormikInput}
                name='delivery_radius'
                type='number'
                label='What is your maximum delivery radius (km)'
                placeholder='Enter delivery radius (km)'
              />
            </div>
            <div className={clsx(classes.fieldWrap, classes.deliveryFee)}>
              <Field
                component={FormikInput}
                name='delivery_fee'
                type='number'
                label={`What is your delivery fee (${getCurrency()})`}
                placeholder={`${getCurrency()} Delivery fee`}
              />
            </div>
            <div className={classes.tooltipWrap}>
              <InfoTooltip text='This fee is applied to all orders unless they reach your Free delivery amount or you have turned off delivery fee for the customer' />
            </div>
            <div className={clsx(classes.fieldWrap, classes.deliveryFee)}>
              <Field
                component={FormikInput}
                name='free_delivery_at'
                type='number'
                label={`Free Delivery At (${getCurrency()})`}
                placeholder={`${getCurrency()} Free Delivery At`}
              />
            </div>
            <div className={classes.tooltipWrap}>
              <InfoTooltip text='Orders over this value will not have a delivery fee applied. Leave this empty to always charge a delivery fee' />
            </div>
            <ButtonsBlock
              isSubmitDisabled={isLoading && !isOnBoarding}
              onSave={submitForm}
              onCancel={closeHandler}
              saveTitle={isOnBoarding ? 'Next' : 'Update'}
              cancelTitle={isOnBoarding ? 'Back' : 'Cancel'}
            />
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
