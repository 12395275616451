import React, { useEffect, useState } from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  value?: number;
  onChange: (value: number) => void;
  onClick?: (...args: unknown[]) => void;
  max?: number;
  min?: number;
  decimals?: number;
}

export const NumberInput: React.FC<Props & Omit<TextFieldProps, 'onChange'>> = React.memo(
  ({ onChange, value, onClick, multiline, max, min, decimals = 2, ...props }) => {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState<number>(0);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (isNaN(+event.target.value)) {
        return;
      }
      if ((!max || (max && +event.target.value < max)) && (!min || (min && +event.target.value > min))) {
        const trimmedValue = +(+event.target.value).toFixed(decimals);
        setInputValue(trimmedValue);
        onChange(trimmedValue);
      }
    };

    useEffect(() => {
      setInputValue(value || 0);
    }, [value]);

    return (
      <div className={classes.box}>
        <TextField
          {...props}
          type='number'
          onClick={onClick}
          hiddenLabel={true}
          value={inputValue}
          onChange={handleOnChange}
          classes={{ root: classes.rootField }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            className: clsx([classes.root, multiline && classes.rootMultiline]),
          }}
        />
      </div>
    );
  },
);
