import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

import { useGeHiddenPages } from '../../../api/teammates/hooks';
import { SubscriptionPlanEnum } from '../../../api/user/types';
import SvgLockIcon from '../../../assets/icons/LockIcon';
import { UpgradeSubscriptionTooltip } from '../../../shared/components/upgrade-subscription-tooltip';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, getMySubscriptionType, logoutThunk } from '../../../store/user';
import { customerLinks, customerLinksNormal, supplierLinks } from '../../AppDrawer/MainNavBar/nested-account-menu';

import { useRippleStyles } from '../../../styles/customRipple';
import { useStyles } from './style';

interface Props {
  showBack?: boolean;
}

export const AccountMenu: React.FC<Props> = ({ showBack }) => {
  const classes = useStyles();
  const rippleClass = useRippleStyles();

  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const hiddenLinks = useGeHiddenPages();

  const logout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(logoutThunk());
  };

  const preventClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  return (
    <div className={classes.accountMenu}>
      {showBack && (
        <NavLink to='/dashboard' className={classes.backLink}>
          <ChevronLeftIcon className={classes.backChevron} />
          Back to Main menu
        </NavLink>
      )}
      <div>
        {(!isRestaurant
          ? [
              ...supplierLinks,
              {
                title: 'Logout',
                link: '/login',
                disabled: false,
                permissionKey: undefined,
              },
            ]
          : subscriptionType === SubscriptionPlanEnum.NORMAL
          ? customerLinksNormal
          : customerLinks
        ).map(({ title, link, disabled, permissionKey }) => (
          <NavLink
            exact={link !== '/account/help' && link !== '/account/billing' && link !== '/account/venue' && !link.includes('/suppliers')}
            key={title}
            onClick={
              disabled ||
              (Array.isArray(hiddenLinks) && permissionKey && hiddenLinks.includes(permissionKey)) ||
              disabled
                ? preventClick
                : title === 'Logout'
                ? logout
                : undefined
            }
            to={link}
            className={
              disabled ||
              (Array.isArray(hiddenLinks) && permissionKey && hiddenLinks.includes(permissionKey))
                ? clsx(classes.menuLink, classes.disabled)
                : clsx(classes.menuLink, rippleClass.ripple)
            }
            activeClassName={classes.menuLinkActive}
          >
            <span> {title}</span>
            {link === '/account/teammates' && !isRestaurant && subscriptionType === SubscriptionPlanEnum.FREE && (
              <UpgradeSubscriptionTooltip text='Upgrade your subscription plan to unlock ‘Teammates’ options' placement={'bottom-end'}>
                <div className={classes.lockIconWrap}>
                  <SvgLockIcon className={classes.lockIcon} />
                </div>
              </UpgradeSubscriptionTooltip>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
