import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useInviteTeammateMutation, useUpdateCustomPermissionsMutation } from '../../../../api/teammates';
import { TeammatePermission } from '../../../../api/teammates/types';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { DropdownMenu } from '../../../../shared/components/dropdown-menu';
import { FormikInput } from '../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { getOptionForRequest } from '../../../../shared/helpers/extract-permission-option';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setCustomTeammatePermissionModal } from '../../../../store/teammates/teammates.actions';
import { CustomPermissionValues } from '../index';

import { useStyles } from './style';
import { UpgradeSubscriptionTooltip } from '../../../../shared/components/upgrade-subscription-tooltip';
import SvgLockIcon from '../../../../assets/icons/LockIcon';
import { getMySubscriptionType } from '../../../../store/user';
import { SubscriptionPlanEnum } from '../../../../api/user/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  customValues: CustomPermissionValues | null;
  setCustomValues: (values: CustomPermissionValues | null) => void;
}

interface Values {
  firstName: string;
  lastName?: string;
  email: string;
}

export const InviteTeammateModal: React.FC<Props> = ({ isOpen, onClose, setCustomValues, customValues }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { isMobile } = useScreenSize();
  const [currentPermission, setCurrentPermission] = useState<TeammatePermission>(TeammatePermission.VIEWER);
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const [inviteTeammate, { isLoading }] = useInviteTeammateMutation();
  const [updatePermission] = useUpdateCustomPermissionsMutation();
  const isNormalUser = (subscriptionType === SubscriptionPlanEnum.FREE || subscriptionType === SubscriptionPlanEnum.NORMAL) 

  const schema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Enter correct email!'),
    firstName: Yup.string().required('Required field'),
  });

  const onSubmit = async ({ firstName, lastName, email }: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    inviteTeammate({
      teammate: {
        first_name: firstName,
        last_name: lastName,
        email,
      },
      permission: currentPermission,
      skipInvalidation: !!(currentPermission === TeammatePermission.CUSTOM && customValues),
    }).then((res) => {
      if ('data' in res && res?.data?.teammate) {
        customValues &&
          res?.data?.teammate.permission === TeammatePermission.CUSTOM &&
          updatePermission({
            id: res.data.teammate.id,
            custom_permission: {
              pantry_lists: getOptionForRequest(customValues.pantry_lists),
              supplier_and_categories: getOptionForRequest(customValues.supplier_and_categories),
              order_history: getOptionForRequest(customValues.order_history),
              dashboard: getOptionForRequest(customValues.dashboard),
              teammates: getOptionForRequest(customValues.teammates),
              vanue_info: getOptionForRequest(customValues.vanue_info),
              billing: getOptionForRequest(customValues.billing),
              menu_planning: getOptionForRequest(customValues.menu_planning),
              sales_and_wastages: getOptionForRequest(customValues.sales_and_wastages),
              automated_ordering: getOptionForRequest(customValues.automated_ordering),
              inventory: getOptionForRequest(customValues.inventory),
              reports: getOptionForRequest(customValues.reports),
              product_type_categories: {
                meat: customValues.meat,
                dry_and_frozen_goods: customValues.dry_and_frozen_goods,
                poultry: customValues.poultry,
                fruits_and_vegetables: customValues.fruits_and_vegetables,
                seafood: customValues.seafood,
                bakery: customValues.bakery,
                coffee_and_tea: customValues.coffee_and_tea,
                dairy: customValues.dairy,
                alchohol_and_beverages: customValues.alchohol_and_beverages,
                small_goods: customValues.small_goods,
                hospitality_supplies: customValues.hospitality_supplies,
              },
            },
          });
        ToastService.success(`You have invited ${firstName} ${lastName || ''} to your team`, 'invite_teammate', 'Teammate invite sent');
        setCustomValues(null);
        setCurrentPermission(TeammatePermission.VIEWER);
        onClose();
      }
    });
  };

  const onSetPermission = (perm: TeammatePermission) => {
    if (isNormalUser && perm !== TeammatePermission.VIEWER) {
      return;
    }
    if (perm === TeammatePermission.CUSTOM) {
      dispatch(setCustomTeammatePermissionModal(-1));
    } else {
      setCustomValues(null);
      dispatch(setCustomTeammatePermissionModal(null));
    }
    setCurrentPermission(perm);
  };

  return (
    <OverlayModal isOpen={isOpen} onClose={onClose} title='Invite Teammate' boxClassName={classes.modalRoot}>
      <Formik
        initialValues={{
          firstName: '',
          email: '',
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={schema}
      >
        {({ submitForm, isValid, values }) => (
          <Form>
            {isMobile ? (
              <div className={classes.dropdownWrapM}>
                <div className={classes.permLabel}>Permissions</div>
                <DropdownMenu
                  disabledIdx={isNormalUser ? [1, 2, 3] : []}
                  items={[
                    <div key='View' onClick={onSetPermission.bind(null, TeammatePermission.VIEWER)}>
                      Viewer
                    </div>,
                    <div key='Editor' onClick={onSetPermission.bind(null, TeammatePermission.EDITOR)} className={clsx(classes.permissionOption)}>
                      Editor
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                    <div key='Admin' onClick={onSetPermission.bind(null, TeammatePermission.ADMIN)} className={clsx(classes.permissionOption)}>
                      Admin
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                    <div key='Custom Mode' onClick={onSetPermission.bind(null, TeammatePermission.CUSTOM)} className={clsx(classes.permissionOption)}>
                      Custom Mode
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                  ]}
                  triggerBtn={
                    <div
                      className={clsx(
                        classes.optionTrigger,
                        currentPermission === TeammatePermission.CUSTOM && classes.optionTriggerCustom,
                      )}
                    >
                      <span>{currentPermission}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  }
                />
              </div>
            ) : null}

            <div className={classes.nameFields}>
              <div className={classes.fieldWrap}>
                <FastField component={FormikInput} name='firstName' label='First Name' placeholder='First Name' />
              </div>
              <div className={classes.fieldWrap}>
                <FastField component={FormikInput} name='lastName' label='Last Name' placeholder='Last Name' />
              </div>
            </div>
            <div className={classes.emailField}>
              <FastField component={FormikInput} name='email' label='Invite by email' placeholder='Email' />
            </div>

            <div className={classes.bottomRow}>
              <div className={classes.dropdownWrap}>
                <div className={classes.permLabel}>Permissions</div>
                <DropdownMenu
                  disabledIdx={isNormalUser ? [1, 2, 3] : []}
                  items={[
                    <div key='View' onClick={onSetPermission.bind(null, TeammatePermission.VIEWER)}>
                      Viewer
                    </div>,
                    <div key='Editor' onClick={onSetPermission.bind(null, TeammatePermission.EDITOR)} className={clsx(classes.permissionOption)}>
                      Editor
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                    <div key='Admin' onClick={onSetPermission.bind(null, TeammatePermission.ADMIN)} className={clsx(classes.permissionOption)}>
                      Admin
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                    <div key='Custom Mode' onClick={onSetPermission.bind(null, TeammatePermission.CUSTOM)} className={clsx(classes.permissionOption)}>
                      Custom Mode
                      {isNormalUser && <div className={classes.lockIconWrap}>
                        <SvgLockIcon className={classes.lockIcon} />
                      </div>}
                    </div>,
                  ]}
                  triggerBtn={
                    <div
                      className={clsx(
                        classes.optionTrigger,
                        currentPermission === TeammatePermission.CUSTOM && classes.optionTriggerCustom,
                      )}
                    >
                      <span>{currentPermission}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  }
                />
              </div>
              <div className={classes.btnsBox}>
                <ThemedButton onClick={onClose} title='Cancel' buttonStyle='secondary' width={isMobile ? 136 : undefined} />
                <ThemedButton
                  onClick={submitForm}
                  title={isMobile ? 'Invite' : 'Send Invite'}
                  disabled={!values.firstName || !values.email || !isValid || isLoading}
                  width={isMobile ? 136 : undefined}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
