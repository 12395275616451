import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      padding: 40,
      width: 750,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '90vw',
        padding: '30px 20px',
      },
    },
    linkWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 30,
      '&>div:first-child': {
        width: 'calc(100% - 150px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    linkField: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      border: `1px solid rgba(152, 165, 183, 0.7)`,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      color: colorVariables.grey80,
      padding: '15px',
      overflow: 'hidden',
    },
    label: {
      fontWeight: 600,
      marginBottom: 12,
    },
    nameFields: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    fieldWrap: {
      width: '48%',
      '& label': {
        fontWeight: 'bold !important',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 10,
        '& label': {
          fontWeight: 'normal !important',
        },
      },
    },
    emailField: {
      margin: '20px 0',
      '& label': {
        fontWeight: 'bold !important',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: 0,
        marginBottom: 20,
        '& label': {
          fontWeight: 'normal !important',
        },
      },
    },
    bottomRow: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
    },
    btnsBox: {
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    permLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      fontWeight: 600,
      marginRight: 10,
    },
    dropdownWrap: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    dropdownWrapM: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '10px 0',
    },
    mobileSelect: {
      maxWidth: 150,
    },
    shareBtn: {
      marginBottom: 15,
      marginLeft: 20,
    },
    optionTrigger: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      color: colorVariables.grey80,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    optionTriggerCustom: {
      '& span': {
        color: colorVariables.blue,
      },
    },
    lockIcon: {
      color: colorVariables.grey80,
      opacity: 1,
      fontSize: 16,
      position: 'relative',
      top: '-8px',
    },
    lockIconWrap: {
      height: 25,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginLeft: 'auto',
    },
    permissionOption: {
      display: 'flex',
    },
  }),
);
